import React from "react";
import { Switch, Route } from "react-router-dom";

import Noticias from "../pages/Noticias";
import NoticiaDetalhe from "../pages/NoticiaDetalhe";
import NotFound from "../pages/404";

const RouteNoticias = () => {
  return (
    <Switch>
      <Route path="/pt/acontece/notícias" exact component={Noticias} />
      <Route path="/pt/acontece/notícias/inexistente" exact component={NotFound} />
      <Route path="/pt/acontece/notícias/:id" component={NoticiaDetalhe} />

      <Route path="/en/happening/news" exact component={Noticias} />
      <Route path="/en/happening/news/not-found" exact component={NotFound} />
      <Route path="/en/happening/news/:id" component={NoticiaDetalhe} />
    </Switch>
  );
};

export default RouteNoticias;
