import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Map from "../components/Map";
import Loading from "../components/Loading";
import SocialShare from "../components/SocialShare";
import Related from "../components/Related";
import ImagesSlider from "../components/ImagesSlider";

import { convertDMS } from "../helpers/coordinates";

const addMarker = (map, link) => {
  return new window.google.maps.Marker({
    map,
    position: link.coords,
  });
};

const RestauranteDetalhe = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { slug } = useParams();
  let history = useHistory();

  const { data: restaurant, isLoaded: loaded, isError } = useApiData(
    `${Settings.server}/api/v1/restaurant/${slug}?expand=timetables,media&lang=${language}`
  );

  if (isError) {
    history.push(`/${language}/${t("slugs.restaurants")}/404`);
  }

  const { data: related, isLoaded: relatedLoaded } = useApiData(
    `${Settings.server}/api/v1/product?per-page=4lang=${language}`
  );

  const renderTime = (times) => {
    if (!times.length) {
      return <p className="font-reg-light">{t("labels.noTimetable")}</p>;
    }

    if (times[0].days !== "" && times[0].open !== "" && times[0].close !== "") {
      let timeFormat = [];
      let allDays = [];
      // SORT AND INT
      times.forEach((time) => {
        let daysOrder = [];

        time.days.forEach((day) => {
          daysOrder.push(Number(day));
          allDays.push(Number(day));
        });

        timeFormat.push({
          open: time.open,
          close: time.close,
          days: daysOrder.sort(),
        });
      });

      let timeToRender = [];

      const daysText = [
        t("labels.sunday"),
        t("labels.monday"),
        t("labels.tuesday"),
        t("labels.wednesday"),
        t("labels.thursday"),
        t("labels.friday"),
        t("labels.saturday"),
        t("labels.holidays"),
      ];

      timeFormat.forEach((time, index) => {
        if (time.days.length > 1) {
          let number1;
          let number2;
          let daysString;
          let sequence = [];

          for (let i = 0; i <= time.days.length - 1; i++) {
            number1 = Number(time.days[i]);
            number2 = Number(time.days[i - 1]);

            if (number1 === 0) {
              number1 = 1;
              number2 = 1 - 1;
            }

            let total = number1 - number2;

            if (total !== 1) {
              sequence.push(false);
            } else {
              sequence.push(true);
            }
          }

          let findFalse = sequence.indexOf(false);

          if (findFalse === -1) {
            timeToRender.push({
              days:
                daysText[time.days[0]] +
                " " +
                "a" +
                " " +
                daysText[time.days[time.days.length - 1]],
              open: time.open,
              close: time.close,
            });
          } else {
            time.days.forEach((value, index) => {
              if (index === 0) {
                daysString = daysText[value];
              } else if (index === value.length - 1) {
                daysString = daysString + ", " + daysText[value];
              } else {
                daysString = daysString + ", " + daysText[value];
              }
            });

            timeToRender.push({
              days: daysString,
              open: time.open,
              close: time.close,
            });
          }
        } else {
          timeToRender.push({
            days: daysText[time.days[0]],
            open: time.open,
            close: time.close,
          });
        }
      });

      let closeDays = [];

      if (!allDays.includes(0)) {
        closeDays.push(0);
      }
      if (!allDays.includes(1)) {
        closeDays.push(1);
      }
      if (!allDays.includes(2)) {
        closeDays.push(2);
      }
      if (!allDays.includes(3)) {
        closeDays.push(3);
      }
      if (!allDays.includes(4)) {
        closeDays.push(4);
      }
      if (!allDays.includes(5)) {
        closeDays.push(5);
      }
      if (!allDays.includes(6)) {
        closeDays.push(6);
      }
      if (!allDays.includes(7)) {
        closeDays.push(7);
      }

      closeDays.sort();

      let timeClose = [];

      if (closeDays.length > 0) {
        if (closeDays.length > 1) {
          let daysString;
          closeDays.forEach((value, index) => {
            if (index === 0) {
              daysString = daysText[value];
            } else if (index === value.length - 1) {
              daysString = daysString + ", " + daysText[value];
            } else {
              daysString = daysString + ", " + daysText[value];
            }
          });
          timeClose.push({
            days: daysString,
            text: t("labels.closed"),
          });
        } else {
          timeClose.push({
            days: daysText[closeDays[0]],
            text: t("labels.closed"),
          });
        }
      }

      let renderTimeText = [];
      let renderTimeCLose = [];

      timeToRender.length > 0 &&
        timeToRender.forEach((value, index) => {
          renderTimeText.push(
            <p key={index} className="font-reg-light">
              {value.days} - {value.open} {t("labels.to")} {value.close}
            </p>
          );
        });

      timeClose.length > 0 &&
        timeClose.forEach((value, index) => {
          renderTimeCLose.push(
            <p key={index} className="font-reg-light">
              {value.days} - {value.text}
            </p>
          );
        });

      return (
        <>
          {renderTimeText}
          {renderTimeCLose}
        </>
      );
    }
  };

  const outputCategory = (list) => {
    const separator = " " + t("labels.and") + " ";
    switch (list.length) {
      case 0:
        return "";
      case 1:
        return list[0];
      case 2:
        return list.join(separator);
      default:
        return list.slice(0, -1).join(", ") + separator + list.slice(-1);
    }
  };

  return (
    <>
      <NavBar active="gastronomia" />

      {loaded ? (
        <main>
          <div
            className="relative flex items-center justify-center bg-local bg-center bg-cover h-detail-heading"
            style={{
              backgroundImage: restaurant?.media?.hasOwnProperty("3")
                ? `url(${restaurant?.media["3"][0]?.path})`
                : "none",
            }}
          >
            <div className="absolute top-0 left-0 w-full h-full bg-overlay-5">
              <div className="flex flex-col items-center justify-center h-full mx-auto text-white lg:items-start lg:w-reg-grid">
                <div className="w-10/12 text-white lg:w-1/2">
                  <h1 className="text-3xl leading-8 text-center uppercase lg:text-left lg:text-5xl lg:leading-14 font-reg-bold">
                    {t("labels.welcome")} <span>{restaurant?.name}</span>
                  </h1>
                </div>

                <div className="justify-between invisible hidden w-7/12 pt-7 lg:flex lg:visible">
                  <div>
                    <p className="font-reg-bold">{t("labels.timetable")}:</p>
                    {restaurant?.timetables &&
                      renderTime(restaurant?.timetables)}
                  </div>
                  <div className="sm:ml-8 md:ml-0">
                    <p className="font-reg-bold">{t("labels.contacts")}:</p>
                    <p className="font-reg-light">
                      {t("labels.phone")} - <span>{restaurant?.contact}</span>
                    </p>
                    {restaurant?.email && (
                      <p className="font-reg-light">
                        E-mail -{" "}
                        <a href={`mailto:${restaurant?.email}`}>
                          {restaurant?.email}
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col visible block w-10/12 mx-auto sm:flex-row pt-7 lg:hidden lg:invisible text-blue">
            <div>
              <p className="font-reg-bold">{t("labels.timetable")}:</p>
              {restaurant?.timetables && renderTime(restaurant?.timetables)}
            </div>
            <div className="mt-4 ml-0 sm:ml-8 lg:ml-0 sm:mt-0">
              <p className="font-reg-bold">{t("labels.contacts")}:</p>
              <p className="font-reg-light">
                {t("labels.phone")} - <span>{restaurant?.contact}</span>
              </p>
              {restaurant?.email && (
                <p className="font-reg-light">
                  E-mail -{" "}
                  <a href={`mailto:${restaurant?.email}`}>
                    {restaurant?.email}
                  </a>
                </p>
              )}
            </div>
          </div>

          <section className="w-10/12 py-10 mx-auto bg-white lg:w-reg-grid lg:py-22">
            <div className="flex flex-col flex-col-reverse lg:flex-row">
              <div className="ml-0 lg:-ml-25 text-blue">
                <SocialShare />
              </div>

              <div className="w-1/12" />

              <div className="flex flex-col lg:w-11/12 lg:flex-row">
                <div className="flex flex-col lg:w-2/3">
                  <h2 className="pb-10 text-2xl lg:text-3xl text-blue font-reg-bold header-prefix">
                    {t("labels.aboutRestaurant")}
                  </h2>

                  {restaurant?.description.length > 0 && (
                    <div
                      className="text-base text-blue institutional-content font-reg-light"
                      dangerouslySetInnerHTML={{
                        __html: restaurant?.description?.trim(),
                      }}
                    />
                  )}

                  {restaurant?.categories?.length && (
                    <div className="flex flex-row pt-8 text-sm text-blue">
                      <div className="mr-12">
                        <p className="font-reg-bold">
                          {t("labels.gastronomy")}:
                        </p>
                        <p className="leading-5 font-reg-light">
                          {outputCategory(restaurant.categories)}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="flex flex-row flex-wrap justify-between pt-4 text-sm md:justify-start md:pt-8 text-blue">
                    <div className="md:mr-12">
                      <p className="font-reg-bold">{t("labels.county")}:</p>
                      <p className="leading-5 font-reg-light">
                        {restaurant?.country}
                      </p>
                    </div>
                    <div className="md:mr-12">
                      <p className="font-reg-bold">Website:</p>
                      <p className="leading-5 font-reg-light">
                        {restaurant?.website ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={restaurant?.website}
                          >
                            {restaurant?.website}
                          </a>
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                    <div>
                      <p className="font-reg-bold">
                        {t("labels.accessibility")}:
                      </p>
                      <p className="leading-5 font-reg-light">
                        {restaurant?.features?.indexOf("3") >= 0
                          ? t("labels.yes")
                          : t("labels.no")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-8 lg:w-1/3 lg:mt-0 lg:ml-7">
                  <Map
                    options={{
                      center: {
                        lat: parseFloat(restaurant?.coordinates?.lat),
                        lng: parseFloat(restaurant?.coordinates?.lon),
                      },
                      zoom: 15,
                      disableDefaultUI: true,
                    }}
                    onMount={addMarker}
                    onMountProps={{
                      coords: {
                        lat: parseFloat(restaurant?.coordinates?.lat),
                        lng: parseFloat(restaurant?.coordinates?.lon),
                      },
                    }}
                  />
                  <div className="pt-4 text-sm text-blue">
                    <div className="mb-2">
                      <p className="font-reg-bold">{t("labels.location")}</p>
                      <p className="leading-5 font-reg-light">
                        {restaurant?.address}
                      </p>
                    </div>
                    <div>
                      <p className="font-reg-bold">{t("labels.coordinates")}</p>
                      <p className="leading-5 font-reg-light">
                        {convertDMS(
                          restaurant?.coordinates?.lat,
                          restaurant?.coordinates?.lon
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {restaurant?.media?.hasOwnProperty("2") &&
            restaurant?.media["2"]?.length > 0 && (
              <ImagesSlider
                images={restaurant?.media["2"]}
                label={restaurant.name}
              />
            )}

          {relatedLoaded && related.length > 0 && (
            <section className="py-8 bg-gray md:pt-16 md:pb-16 lg:pt-20 lg:pb-20">
              <div className="mx-auto md:max-w-reg-grid">
                <Related type="product" items={related} columns={4} shuffle />
              </div>
            </section>
          )}
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default RestauranteDetalhe;
