import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import heroImage from "../assets/images/contacts-hero.png";

const Contactos = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { data: contacts, isLoaded: loadedContacts } = useApiData(
    `${Settings.server}/api/v1/institutional?page_url=contacts&lang=${language}`
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${Settings.server}/api/v1/institutional/contact?lang=${language}`,
        {
          nome: name.value,
          email: email.value,
          assunto: subject.value,
          mensagem: message.value,
        }
      )
      .then((response) => {
        setName({
          active: false,
          value: "",
        });
        setEmail({
          active: false,
          value: "",
        });
        setSubject({
          active: false,
          value: "",
        });
        setMessage({
          active: false,
          value: "",
        });
        setEnabledButton(false);
        setShowSuccess(true);
      })
      .catch((error) => {
        const errors = error.response?.data?.errors;

        if (errors && errors.hasOwnProperty("nome")) {
          setNameErrors(errors.nome);
        }

        if (errors && errors.hasOwnProperty("email")) {
          setEmailErrors(errors.email);
        }

        if (errors && errors.hasOwnProperty("assunto")) {
          setSubjectErrors(errors.assunto);
        }

        if (errors && errors.hasOwnProperty("mensagem")) {
          setMessageErrors(errors.mensagem);
        }
      });
  };

  const [name, setName] = useState({
    value: "",
    active: false,
  });
  const [nameErrors, setNameErrors] = useState([]);

  const [email, setEmail] = useState({
    value: "",
    active: false,
  });
  const [emailErrors, setEmailErrors] = useState([]);

  const [subject, setSubject] = useState({
    value: "",
    active: false,
  });
  const [subjectErrors, setSubjectErrors] = useState([]);

  const [message, setMessage] = useState({
    value: "",
    active: false,
  });
  const [messageErrors, setMessageErrors] = useState([]);

  const [enabledButton, setEnabledButton] = useState(false);
  useEffect(() => {
    if (
      name.value.length > 0 &&
      email.value.length > 0 &&
      subject.value.length > 0 &&
      message.value.length > 0
    ) {
      setEnabledButton(true);
    }

    return () => {
      setEnabledButton(false);
    };
  }, [name, email, subject, message]);

  const [showSuccess, setShowSuccess] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showSuccess) {
        setShowSuccess(false);
      }
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [showSuccess]);

  const nameInputClass =
    "block w-full px-4 py-3 mt-1 text-sm leading-4 rounded form-input font-reg-light " +
    (nameErrors.length > 0 ? "text-red-500 border-red-500" : "text-blue");
  const emailInputClass =
    "block w-full px-4 py-3 mt-1 text-sm leading-4 rounded form-input font-reg-light " +
    (emailErrors.length > 0 ? "text-red-500 border-red-500" : "text-blue");
  const subjectInputClass =
    "block w-full px-4 py-3 mt-1 text-sm leading-4 rounded form-input font-reg-light " +
    (subjectErrors.length > 0 ? "text-red-500 border-red-500" : "text-blue");
  const messageInputClass =
    "block w-full px-4 py-3 mt-1 text-sm leading-4 rounded form-textarea font-reg-light " +
    (messageErrors.length > 0 ? "text-red-500 border-red-500" : "text-blue");

  const nameSpanClass =
    "text-base leading-4 font-reg " +
    (nameErrors.length > 0 ? "text-red-500" : "text-blue");
  const emailSpanClass =
    "text-base leading-4 font-reg " +
    (emailErrors.length > 0 ? "text-red-500" : "text-blue");
  const subjectSpanClass =
    "text-base leading-4 font-reg " +
    (subjectErrors.length > 0 ? "text-red-500" : "text-blue");
  const messageSpanClass =
    "text-base leading-4 font-reg " +
    (messageErrors.length > 0 ? "text-red-500" : "text-blue");

  const nameLabelClass =
    "block" + (!name.active ? " opacity-50 hover:opacity-100" : "");
  const emailLabelClass =
    "block" + (!email.active ? " opacity-50 hover:opacity-100" : "");
  const subjectLabelClass =
    "block" + (!subject.active ? " opacity-50 hover:opacity-100" : "");
  const messageLabelClass =
    "block" + (!message.active ? " opacity-50 hover:opacity-100" : "");

  const buttonClass =
    "w-full py-3 text-lg leading-6 font-reg border-2 rounded " +
    (enabledButton
      ? "text-white bg-blue hover:bg-transparent hover:text-blue hover:border-blue"
      : "bg-white cursor-default text-grayish-blue border-grayish-blue text-gray");

  const successMessageClass =
    "w-2/3 mx-auto mt-10 transform transition-opacity duration-300 ease-out " +
    (showSuccess ? "opacity-100" : "opacity-0");

  return (
    <>
      <NavBar active="contactos" />

      <main className="bg-gray">
        <div
          className="relative flex items-center justify-center bg-local bg-center bg-cover h-72 sm:h-80 md:h-heading"
          style={{
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-overlay-3">
            <h1 className="text-4xl text-center text-white sm:text-5xl md:text-6xl lg:text-7xl font-reg sm:font-reg-light">
              {t("contacts.title")}
            </h1>
          </div>
        </div>

        <section className="w-10/12 pt-8 pb-4 mx-auto md:pb-10 md:pt-16 lg:pt-26 lg:w-reg-grid">
          <div className="mx-auto md:w-8/12">
            <h2 className="pb-8 text-2xl md:pb-10 md:text-3xl text-blue font-reg-bold header-prefix">
              {t("contacts.subtitles.info")}
            </h2>

            <dl className="w-full pr-0 text-base leading-5 md:w-1/2 text-blue md:pr-7">
              <dt className="font-reg-bold">{t("contacts.labels.entity")} :</dt>
              <dd className="pt-2 font-reg-light">
                Comunidade Intermunicipal Região de Coimbra (CIM RC)
              </dd>
            </dl>

            <dl className="w-full pt-5 pr-0 text-base leading-5 md:w-1/2 text-blue md:pr-7">
              <dt className="font-reg-bold">
                {t("contacts.labels.location")} :
              </dt>
              <dd
                className="pt-2 font-reg-light"
                dangerouslySetInnerHTML={{
                  __html: loadedContacts
                    ? contacts
                        .find((item) => item.section === "address")
                        ?.content.trim()
                    : "",
                }}
              />
            </dl>

            <div className="flex flex-col w-full md:flex-row">
              <dl className="w-full pt-5 pr-0 text-base leading-5 md:w-1/2 text-blue md:pr-7">
                <dt className="font-reg-bold">
                  {t("contacts.labels.contacts")} :
                </dt>
                <dd className="pt-2 font-reg-light">
                  {t("contacts.labels.phone")}:{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: loadedContacts
                        ? contacts
                            .find((item) => item.section === "phone")
                            ?.content.replace(/<[^>]*>?/gm, "")
                            .trim()
                        : "",
                    }}
                  />
                </dd>
              </dl>
              <dl className="w-full pt-5 pr-0 text-base leading-5 md:w-1/2 text-blue md:pl-7">
                <dt className="font-reg-bold">
                  {t("contacts.labels.email")} :
                </dt>
                <dd
                  className="pt-2 font-reg-light"
                  dangerouslySetInnerHTML={{
                    __html: loadedContacts
                      ? contacts
                          .find((item) => item.section === "email")
                          ?.content.trim()
                      : "",
                  }}
                />
              </dl>
            </div>

            <div className="pt-8 md:pt-16 lg:pt-20">
              <h2 className="pb-8 text-2xl md:pb-10 md:text-3xl text-blue font-reg-bold header-prefix">
                {t("contacts.subtitles.contact")}
              </h2>

              <div className="flex flex-col justify-between md:flex-row">
                <div className="relative w-full md:w-1/2 md:mr-3">
                  <label className={nameLabelClass}>
                    <span className={nameSpanClass}>
                      {t("contacts.labels.name")}*
                    </span>
                    <input
                      className={nameInputClass}
                      placeholder={t("contacts.placeholders.name")}
                      onBlur={(e) => {
                        e.target.placeholder = t("contacts.placeholders.name");
                        setName({ ...name, active: e.target.value.length > 0 });
                      }}
                      onFocus={(e) => {
                        e.target.placeholder = "";
                        setName({ ...name, active: true });
                      }}
                      value={name.value || ""}
                      onChange={(e) => {
                        setName({
                          ...name,
                          value: e.target.value,
                        });
                        setNameErrors([]);
                      }}
                    />
                  </label>
                  {nameErrors.length > 0 &&
                    nameErrors.map((err, i) => (
                      <span
                        key={i}
                        className="absolute ml-1 -mb-4 text-xs text-red-500 sm:text-sm leading-0 font-reg-light bottom-1"
                      >
                        {err}
                      </span>
                    ))}
                </div>

                <div className="relative w-full pt-4 md:w-1/2 md:pt-0 md:ml-3">
                  <label className={emailLabelClass}>
                    <span className={emailSpanClass}>
                      {t("contacts.labels.email")}*
                    </span>
                    <input
                      className={emailInputClass}
                      placeholder={t("contacts.placeholders.email")}
                      onBlur={(e) => {
                        e.target.placeholder = t("contacts.placeholders.email");
                        setEmail({
                          ...email,
                          active: e.target.value.length > 0,
                        });
                      }}
                      onFocus={(e) => {
                        e.target.placeholder = "";
                        setEmail({ ...email, active: true });
                      }}
                      value={email.value || ""}
                      onChange={(e) => {
                        setEmail({
                          ...email,
                          value: e.target.value,
                        });
                        setEmailErrors([]);
                      }}
                    />
                  </label>
                  {emailErrors.length > 0 &&
                    emailErrors.map((err, i) => (
                      <span
                        key={i}
                        className="absolute ml-1 -mb-4 text-xs text-red-500 sm:text-sm leading-0 font-reg-light bottom-1"
                      >
                        {err}
                      </span>
                    ))}
                </div>
              </div>

              <div className="relative pt-4 md:pt-7">
                <label className={subjectLabelClass}>
                  <span className={subjectSpanClass}>
                    {t("contacts.labels.subject")}*
                  </span>
                  <input
                    className={subjectInputClass}
                    placeholder={t("contacts.placeholders.subject")}
                    onBlur={(e) => {
                      e.target.placeholder = t("contacts.placeholders.subject");
                      setSubject({
                        ...subject,
                        active: e.target.value.length > 0,
                      });
                    }}
                    onFocus={(e) => {
                      e.target.placeholder = "";
                      setSubject({ ...subject, active: true });
                    }}
                    value={subject.value || ""}
                    onChange={(e) => {
                      setSubject({
                        ...subject,
                        value: e.target.value,
                      });
                      setSubjectErrors([]);
                    }}
                  />
                </label>
                {subjectErrors.length > 0 &&
                  subjectErrors.map((err, i) => (
                    <span
                      key={i}
                      className="absolute ml-1 -mb-4 text-xs text-red-500 sm:text-sm leading-0 font-reg-light bottom-1"
                    >
                      {err}
                    </span>
                  ))}
              </div>

              <div className="relative pt-4 md:pt-7">
                <label className={messageLabelClass}>
                  <span className={messageSpanClass}>
                    {t("contacts.labels.text")}*
                  </span>
                  <textarea
                    className={messageInputClass}
                    rows="10"
                    placeholder={t("contacts.placeholders.text")}
                    onBlur={(e) => {
                      e.target.placeholder = t("contacts.placeholders.text");
                      setMessage({
                        ...message,
                        active: e.target.value.length > 0,
                      });
                    }}
                    onFocus={(e) => {
                      e.target.placeholder = "";
                      setMessage({ ...message, active: true });
                    }}
                    value={message.value || ""}
                    onChange={(e) => {
                      setMessage({
                        ...message,
                        value: e.target.value,
                      });
                      setMessageErrors([]);
                    }}
                  />
                </label>
                {messageErrors.length > 0 &&
                  messageErrors.map((err, i) => (
                    <span
                      key={i}
                      className="absolute ml-1 -mb-4 text-xs text-red-500 sm:text-sm leading-0 font-reg-light bottom-1"
                    >
                      {err}
                    </span>
                  ))}
              </div>

              <div className="w-full mx-auto mt-8 md:mt-10 sm:w-1/2">
                <button
                  type="submit"
                  disabled={!enabledButton}
                  className={buttonClass}
                  onClick={handleSubmit}
                >
                  {t("contacts.labels.send")}
                </button>
              </div>

              <div className={successMessageClass}>
                <div
                  className="relative px-4 py-3 text-base text-center border rounded bg-teal-50 border-blue font-reg-light text-blue"
                  role="alert"
                >
                  <strong className="font-reg-bold">
                    {t("labels.success")}!{" "}
                  </strong>
                  <span className="block sm:inline">
                    {t("labels.contactSent")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Contactos;
