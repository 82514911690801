import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import LanguageSwitcher from "../components/LanguageSwitcher";

import logoReg from "../assets/logo/logo-rc-reg2021-white.svg";
import logoTurismoPT from "../assets/logo-turismo-pt-white.svg";
import logoTurismoRC from "../assets/logo-turismo-rc-white.svg";

const BulletSpacer = () => <>&nbsp;&bull;&nbsp;</>;

function Footer() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  // fetch Contacts
  const { data: contacts, isLoaded: contactsLoaded } = useApiData(
    `${Settings.server}/api/v1/institutional?page_url=contacts&lang=${language}`
  );

  // fetch Social Networks
  const { data: socialNetworks, isLoaded: socialNetworksLoaded } = useApiData(
    `${Settings.server}/api/v1/institutional?page_url=social-networks&lang=${language}`
  );

  const [acceptNewsletter, setAcceptNewsletter] = useState(false);
  const [newsletterError, setNewsletterError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (subscribeSuccess) {
        setSubscribeSuccess(false);
      }
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [subscribeSuccess]);

  const handleSubscribe = (e) => {
    e.preventDefault();

    if (!email.length) {
      setEmailError(true);
    }

    if (!acceptNewsletter) {
      setNewsletterError(true);
      return false;
    }

    axios
      .post(`${Settings.server}/api/v1/institutional/subscribe`, { email })
      .then((response) => {
        setEmail("");
        setAcceptNewsletter(false);
        setSubscribeSuccess(true);
      })
      .catch((error) => {
        setEmailError(true);
      });
  };

  const newsletterClass =
    "pt-2 font-light cursor-pointer text-xxs" +
    (newsletterError ? " has-error text-red-500" : "");
  const emailClass =
    "w-full p-4 text-sm bg-transparent border border-white h-3/12" +
    (emailError ? " border-red-500" : "");

  return (
    <footer className="text-white bg-blue md:px-8">
      <div className="mx-auto max-w-reg">
        <div className="flex flex-col items-center justify-between pb-8 text-center md:flex-row pt-14 font-reg-light md:text-left">
          <div className="pb-16 md:pb-0">
            <dl>
              <dt className="text-2xl font-reg-bold">
                {t("labels.socialNetworks")}
              </dt>
              <dd className="pt-4 text-lg font-reg-light">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    socialNetworksLoaded
                      ? socialNetworks
                          .find((item) => item.section === "facebook")
                          .content.replace(/<[^>]*>?/gm, "")
                          .trim()
                      : "https://facebook.com"
                  }
                  className="underline hover:no-underline"
                >
                  Facebook
                </a>
                {socialNetworksLoaded && <BulletSpacer />}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    socialNetworksLoaded
                      ? socialNetworks
                          .find((item) => item.section === "instagram")
                          .content.replace(/<[^>]*>?/gm, "")
                          .trim()
                      : "https://instagram.com"
                  }
                  className="underline hover:no-underline"
                >
                  Instagram
                </a>
                {socialNetworksLoaded && <BulletSpacer />}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    socialNetworksLoaded
                      ? socialNetworks
                          .find((item) => item.section === "youtube")
                          .content.replace(/<[^>]*>?/gm, "")
                          .trim()
                      : "https://youtube.com"
                  }
                  className="underline hover:no-underline"
                >
                  Youtube
                </a>
              </dd>
            </dl>
            <div
              className="pt-12 font-light"
              dangerouslySetInnerHTML={{
                __html: contactsLoaded
                  ? contacts.find((item) => item.section === "address")?.content
                  : "",
              }}
            />
            <p className="flex flex-col pt-6 font-light md:block">
              <a
                className="hover:underline"
                href={`mailto:${
                  contactsLoaded
                    ? contacts
                        .find((item) => item.section === "email")
                        ?.content.replace(/<[^>]*>?/gm, "")
                        .trim()
                    : ""
                }`}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: contactsLoaded
                      ? contacts
                          .find((item) => item.section === "email")
                          ?.content.replace(/<[^>]*>?/gm, "")
                          .trim()
                      : "",
                  }}
                />
              </a>

              {contactsLoaded && (
                <span className="invisible hidden md:visible md:inline">
                  <BulletSpacer />
                </span>
              )}

              <span
                dangerouslySetInnerHTML={{
                  __html: contactsLoaded
                    ? contacts
                        .find((item) => item.section === "phone")
                        ?.content.replace(/<[^>]*>?/gm, "")
                        .trim()
                    : "",
                }}
              />
            </p>
            <p className="pt-6 font-reg-bold">
              {/*
              <Link
                to={`/${language}/${t("slugs.terms")}`}
                className="hover:underline"
              >
                {t("labels.terms")}
              </Link>
              <BulletSpacer />
              */}
              <Link
                to={`/${language}/${t("slugs.privacy")}`}
                className="hover:underline"
              >
                {t("labels.privacy")}
              </Link>
            </p>
          </div>
          <div className="w-10/12 xs:w-1/3 sm:w-96">
            <p className="pb-4 font-reg-bold">{t("labels.newsletter")}</p>
            <input
              type="email"
              required
              className={emailClass}
              placeholder={t("contacts.placeholders.email")}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) =>
                (e.target.placeholder = t("contacts.placeholders.email"))
              }
              onChange={(e) => {
                if (e.target.value.length) {
                  setEmailError(false);
                  setEmail(e.target.value);
                }
              }}
              value={email}
            />
            <div className="flex items-center justify-between mt-4">
              <input
                id="newsletter-checkbox"
                type="checkbox"
                className="square-checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setNewsletterError(false);
                    setAcceptNewsletter(true);
                  } else {
                    setAcceptNewsletter(false);
                  }
                }}
                checked={acceptNewsletter}
              />
              <label
                htmlFor="newsletter-checkbox"
                className={newsletterClass}
                dangerouslySetInnerHTML={{
                  __html: contactsLoaded
                    ? contacts
                        .find((item) => item.section === "newsletter")
                        ?.content.replace(/<[^>]*>?/gm, "")
                        .trim()
                    : "",
                }}
              />
              <div className="w-4 h-1 pt-2 text-white min-w-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 3"
                  className="fill-current"
                >
                  <defs />
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                  />
                </svg>
              </div>
              <button
                type="button"
                className="pt-2 ml-2 text-xs font-reg-bold hover:underline"
                onClick={handleSubscribe}
              >
                {t("labels.submit")}
              </button>
            </div>
            {subscribeSuccess && (
              <div className="p-1 mt-4 text-center border">
                {t("labels.newsletterSubscribe")}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center mt-6 md:flex-row">
          <img
            src={logoReg}
            alt="Logotipo da Região de Coimbra - Região Europeia de Gastronomia 2021"
            className="h-10"
          />
          <div className="w-1/2 mx-auto mt-8 border-t border-white md:mx-6 md:border-t-0 md:w-0 md:mt-0 md:border-l" />
          <div className="flex justify-center mt-8 md:mt-0">
            <a
              href="https://www.cim-regiaodecoimbra.pt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logoTurismoRC}
                alt="Logotipo do Turismo da Região de Coimbra"
                className="h-10 pr-6"
              />
            </a>
            <a
              href="https://www.turismodeportugal.pt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logoTurismoPT}
                alt="Logotipo do Turismo de Portugal"
                className="h-10"
              />
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center mt-8 text-sm md:justify-end md:-mt-9 font-reg-light">
          <LanguageSwitcher />
          <div className="ml-1 bg-white md:w-10 h-xs" />
        </div>
        <div>
          <p className="py-10 text-xs text-center">
            &copy;{t("labels.rights", { year: new Date().getFullYear() })}
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
