import React from "react";
import { Switch, Route } from "react-router-dom";

import RotaMercados from "../pages/RotaMercados";
//import EventoDetalhe from "../pages/EventoDetalhe";
import NotFound from "../pages/404";

const RouteRotaMercados = () => {
  return (
    <Switch>
      <Route path="/pt/acontece/rota-mercados" exact component={RotaMercados} />
      <Route path="/pt/acontece/rota-mercados/inexistente" exact component={NotFound} />
      {/*<Route path="/pt/acontece/rota-mercados/:id" component={EventoDetalhe} />*/}
      
      <Route path="/en/happening/rota-mercados" exact component={RotaMercados} />
      <Route path="/en/happening/rota-mercados/not-found" exact component={NotFound} />
      {/* <Route path="/en/happening/rota-mercados/:id" component={EventoDetalhe} /> */}

    </Switch>
  );
};

export default RouteRotaMercados;
