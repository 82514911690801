import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import SocialShare from "../components/SocialShare";
import Loading from "../components/Loading";
import Related from "../components/Related";
import ImagesSlider from "../components/ImagesSlider";

const ProdutoDetalhe = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { slug } = useParams();
  let history = useHistory();

  const { data: product, isLoaded: loaded, isError } = useApiData(
    `${Settings.server}/api/v1/product/${slug}?expand=media&lang=${language}`
  );

  if (isError) {
    history.push(`/${language}/${t("slugs.products")}/404`);
  }

  const [related, setRelated] = useState([]);
  const {
    data: relatedProducts,
    isLoaded: relatedProductsLoaded,
    isError: relatedProductsError,
  } = useApiData(
    `${Settings.server}/api/v1/product?per-page=5&lang=${language}`
  );

  useEffect(() => {
    if (relatedProductsLoaded && !relatedProductsError) {
      let productsList = relatedProducts.filter((e) => e.slug !== slug);
      if (productsList.length > 4) {
        productsList.pop();
      }
      setRelated(productsList);
    }
  }, [relatedProducts, slug, relatedProductsLoaded, relatedProductsError]);

  return (
    <>
      <NavBar active="gastronomia" />

      {loaded ? (
        <main>
          <div
            className="relative flex items-center justify-center bg-local bg-center bg-cover h-detail-heading"
            style={{
              backgroundImage: product.media?.hasOwnProperty("4")
                ? `url(${product.media["4"][0]?.path})`
                : "none",
            }}
          >
            <div className="absolute top-0 left-0 w-full h-full bg-overlay-5">
              <div className="flex items-center justify-center h-full mx-auto text-center lg:text-left lg:justify-start lg:w-reg-grid">
                <div className="text-white lg:w-1/2">
                  <h1 className="text-3xl uppercase lg:text-5xl leading-14 font-reg-bold">
                    {product.name}
                  </h1>
                  <div className="invisible pt-7 lg:visible">
                    <p className="font-reg">{t("labels.productType")}:</p>
                    <p className="font-reg-light">{product.category?.name}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="visible block w-10/12 mx-auto mt-8 lg:hidden lg:invisible text-blue">
            <p className="font-reg">{t("labels.productType")}:</p>
            <p className="font-reg-light">{product.category?.name}</p>
          </div>

          <section className="w-10/12 py-10 mx-auto bg-white lg:w-reg-grid md:py-22">
            <div className="flex flex-col flex-col-reverse lg:flex-row">
              <div className="ml-0 lg:-ml-25 text-blue">
                <SocialShare />
              </div>

              <div className="flex flex-col w-full lg:ml-15">
                <h2 className="pb-8 text-2xl text-center md:text-3xl md:pb-14 text-blue font-reg-bold header-prefix">
                  {t("labels.aboutProduct")}
                </h2>

                <div
                  className="text-sm md:text-base text-blue product-content two-columns font-reg-light"
                  dangerouslySetInnerHTML={{
                    __html: product.description,
                  }}
                />
              </div>
            </div>
          </section>

          {product.media?.hasOwnProperty("2") &&
            product.media["2"].length > 0 && (
              <ImagesSlider images={product.media["2"]} label={product.name} />
            )}

          {relatedProductsLoaded && related.length > 0 && (
            <section className="py-8 bg-gray md:pt-16 md:pb-16 lg:pt-20 lg:pb-20">
              <div className="mx-auto md:max-w-reg-grid">
                <Related type="product" items={related} columns={4} shuffle />
              </div>
            </section>
          )}
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default ProdutoDetalhe;
