import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Breakpoint } from "react-socks";
import AliceCarousel from "react-alice-carousel";

import shuffle from "../helpers/shuffle";

import CardProduto from "./CardProduto";
import CardNoticia from "./CardNoticia";
import CardReceita from "./CardReceita";
import CardProdutor from "./CardProdutor";
import CardExperiencia from "./CardExperiencia";
import CardEvento from "./CardEvento";

const Related = ({
  items,
  type,
  shuffle: shuffleArray = false,
  columns = 4,
  hideTitle = false,
  border = false,
}) => {
  const { t } = useTranslation();

  const carousel = useRef(null);

  let content = items;
  if (shuffleArray) {
    content = shuffle(items);
  }

  const translateContentItemToCard = (item, type) => {
    switch (type) {
      case "product":
        return <CardProduto key={item.id} product={item} slider />;
      case "article":
        return (
          <CardNoticia key={item.id} article={item} border={border} slider />
        );
      case "recipe":
        return <CardReceita key={item.id} recipe={item} slider />;
      case "producer":
        return <CardProdutor key={item.id} produtor={item} slider />;
      case "tour":
        return <CardExperiencia key={item.id} tour={item} slider />;
      case "event":
        return <CardEvento key={item.id} event={item} slider />;
      default:
        break;
    }

    return <div />;
  };

  const getResponsive = (type) => {
    if (type === "article") {
      return {
        0: {
          items: 1,
        },
        900: {
          items: 2,
        },
      };
    }
    return {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    };
  };

  let wrapperClass = "flex flex-row lg:grid lg:grid-cols-4 lg:gap-6";
  if (columns !== 4) {
    wrapperClass = "flex flex-row lg:grid lg:grid-cols-3 lg:gap-6";
  }

  if (content.length > 0) {
    return (
      <div className="w-full mx-auto">
        {!hideTitle && (
          <h3 className="pb-4 text-2xl text-center lg:text-3xl lg:text-left text-blue font-reg-bold md:pb-7">
            {t("labels.viewMore")}
          </h3>
        )}
        <Breakpoint customQuery="(max-width: 1169px)">
          <div className="flex flex-row items-center justify-center">
            <button
              type="button"
              className="mb-20 sm:mr-4 text-blue"
              onClick={() => carousel.current.slidePrev()}
            >
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                className="w-12 h-12"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div className="w-2/3 sm:w-10/12">
              <AliceCarousel
                items={content.map((item) =>
                  translateContentItemToCard(item, type)
                )}
                buttonsDisabled={true}
                dotsDisabled={true}
                infinite={false}
                ref={carousel}
                responsive={getResponsive(type)}
              />
            </div>
            <button
              type="button"
              className="mb-20 sm:ml-4 text-blue"
              onClick={() => carousel.current.slideNext()}
            >
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                className="w-12 h-12"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </Breakpoint>
        <Breakpoint customQuery="(min-width: 1170px)">
          <div className={wrapperClass}>
            {content.map((item) => translateContentItemToCard(item, type))}
          </div>
        </Breakpoint>
      </div>
    );
  }

  return null;
};

export default Related;
