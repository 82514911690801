import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const languages = ["pt", "en","fr","es"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    fallbackLng: "pt",
    preload: ["pt", "en","fr","es"],

    debug: process.env.NODE_ENV === "development",

    whitelist: languages,

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ["querystring", "cookie", "localStorage", "htmlTag"],
      lookupQuerystring: "lang",
      lookupCookie: "i18next_lang",
      lookupLocalStorage: "i18next_Lng",
    },
  });

export default i18n;
