import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardProdutor = ({ produtor, slider = false }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const linkClass =
    "overflow-hidden bg-white shadow-card hover:shadow-md gastronomy-card lg:w-full " +
    (slider
      ? "block sm:w-72 my-2 lg:my-0 mx-4 sm:mx-auto h-72 sm:h-auto"
      : "w-64 mx-auto sm:w-80 md:w-72");

  return (
    <Link
      to={`/${language}/${t("slugs.makers")}/${produtor.slug}`}
      className={linkClass}
    >
      <div className="relative bg-white pb-4/5">
        {produtor.media?.hasOwnProperty("5") && (
          <img
            className="absolute object-cover w-full h-full transition-all duration-500 ease-in-out"
            src={produtor.media["5"].path}
            alt={produtor.name}
          />
        )}
      </div>
      <div className="px-4 pt-4 pb-5 bg-white">
        <h4 className="pb-3 text-base leading-4 text-blue font-reg">
          {produtor.name}
        </h4>
        <div className="flex flex-row items-center text-gray-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 489.773 489.773"
            className="w-4 h-4 mr-2"
          >
            <defs />
            <g fill="currentColor">
              <path d="M155.183 305.646c-2.081.175-4.157.265-6.238.265-5.693 0-11.23-.826-16.652-2.081L6.834 429.293c-6.158 6.149-6.158 16.137 0 22.287l32.47 32.478c6.158 6.15 16.135 6.15 22.276 0l150.785-150.757-27.944-30.15-29.238 2.495zM485.345 104.649c-5.888-5.885-15.417-5.885-21.304 0l-81.303 81.301c-7.693 7.685-20.154 7.685-27.847 0-7.659-7.679-7.659-20.13 0-27.807l80.901-80.884c6.112-6.118 6.112-16.036 0-22.168-6.141-6.11-16.055-6.11-22.167 0l-80.868 80.876c-7.693 7.693-20.14 7.693-27.833 0-7.677-7.676-7.677-20.136 0-27.806l81.286-81.293c5.904-5.894 5.904-15.441 0-21.343-5.888-5.895-15.434-5.895-21.338 0l-91.458 91.463c-21.989 22.003-28.935 52.888-21.816 80.991l61.31 61.314c28.101 7.093 59.001.144 80.965-21.841l91.471-91.458c5.905-5.894 5.905-15.451.001-21.345z" />
              <path d="M41.093 13.791c-3.134-3.135-7.372-4.854-11.724-4.854-.926 0-1.857.079-2.766.231-5.295.896-9.838 4.295-12.172 9.133-26.79 55.373-15.594 121.631 27.894 165.121l77.801 77.791c7.676 7.685 18.055 11.939 28.819 11.939 1.151 0 2.305-.048 3.456-.143l45.171-3.855 196.971 212.489c3.058 3.303 7.342 5.221 11.855 5.31h.288c4.412 0 8.636-1.743 11.771-4.855l33.734-33.741c3.117-3.11 4.859-7.331 4.859-11.73 0-4.398-1.742-8.622-4.846-11.732L41.093 13.791z" />
            </g>
          </svg>
          <span className="text-sm font-reg-light">{produtor.category}</span>
        </div>
      </div>
    </Link>
  );
};

export default CardProdutor;
