import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";

const ImagesSlider = ({ images, label }) => {
  const carousel = useRef(null);

  return (
    <section className="relative w-full">
      <div className="absolute left-0 z-10 h-12 transform -translate-y-1/2 text-blue bg-gray top-1/2">
        <button onClick={() => carousel.current.slidePrev()}>
          <svg fill="currentColor" viewBox="0 0 20 20" className="w-12 h-12">
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="absolute right-0 z-10 h-12 transform -translate-y-1/2 text-blue bg-gray top-1/2">
        <button onClick={() => carousel.current.slideNext()}>
          <svg fill="currentColor" viewBox="0 0 20 20" className="w-12 h-12">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <AliceCarousel
        responsive={{
          0: {
            items: 1,
          },
        }}
        buttonsDisabled={true}
        dotsDisabled={true}
        ref={carousel}
        items={images.map((image, i) => (
          <img
            src={image.path}
            alt={label + ' ' + ++i}
            className="object-cover w-full h-125"
          />
        ))}
      />
    </section>
  );
};

export default ImagesSlider;
