import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import SocialShare from "../components/SocialShare";
import Related from "../components/Related";

const NoticiaDetalhe = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { id } = useParams();
  let history = useHistory();

  const { data: article, isLoaded: loaded, isError } = useApiData(
    `${Settings.server}/api/v1/article/${id}?lang=${language}`
  );

  if (isError) {
    history.push(
      `/${language}/${t("slugs.restaurants")}/${t("labels.notFound")}`
    );
  }

  const [related, setRelated] = useState([]);
  const {
    data: relatedArticles,
    isLoaded: relatedArticlesLoaded,
    isError: relatedArticlesError,
  } = useApiData(
    `${Settings.server}/api/v1/article?per-page=4&sort=-publish_date&lang=${language}`
  );

  useEffect(() => {
    if (relatedArticlesLoaded && !relatedArticlesError) {
      let articlesList = relatedArticles.filter(
        (a) => a.id !== parseInt(id, 10)
      );
      if (articlesList.length > 3) {
        articlesList.pop();
      }
      setRelated(articlesList);
    }
  }, [relatedArticles, id, relatedArticlesLoaded, relatedArticlesError]);

  return (
    <>
      <NavBar active="acontece" />

      {loaded ? (
        <main>
          <section className="w-10/12 mx-auto xl:w-reg-grid">
            <div className="flex flex-col justify-around">
              <h1 className="text-center font-reg-bold text-blue text-2xl sm:text-3xl md:text-4xl lg:text-4.5xl pt-8 lg:pt-26">
                {article.title}
              </h1>
              <p
                className="hidden pt-6 pb-8 mx-auto text-base leading-7 text-center md:block sm:w-2/3 lg:pb-14 font-reg-light text-blue"
                dangerouslySetInnerHTML={{
                  __html: loaded
                    ? article.excerpt?.trim().substring(0, 150) + " ..."
                    : "",
                }}
              />
            </div>
          </section>

          <section className="sm:w-10/12 sm:mx-auto xl:w-reg-grid">
            <div className="flex flex-col flex-col-reverse justify-around lg:flex-row">
              <div className="mb-8 ml-0 lg:-ml-25 text-blue lg:mb-0">
                <SocialShare />
              </div>

              <div className="flex flex-col mt-8 lg:w-11/12 lg:mt-0">
                <img
                  className="object-cover object-center w-full h-52 sm:h-80 md:h-96 lg:h-110 mb-14"
                  alt={article.title}
                  src={article.media?.find((m) => m.type === 13)?.path}
                />

                <article
                  className="w-10/12 pb-8 mx-auto text-base institutional-content text-blue font-reg-light lg:pb-26 sm:w-full"
                  dangerouslySetInnerHTML={{
                    __html: loaded ? article.excerpt?.trim() : "",
                  }}
                />
              </div>
            </div>
          </section>

          {relatedArticlesLoaded && (
            <section className="pt-8 pb-8 bg-gray md:pt-16 md:pb-16 lg:pt-20 lg:pb-20">
              <div className="mx-auto md:max-w-reg-grid">
                <Related
                  type="article"
                  items={related}
                  columns={3}
                  border={true}
                />
              </div>
            </section>
          )}
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default NoticiaDetalhe;
