import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import CardProduto from "../components/CardProduto";
import Pagination from "../components/Pagination";

import heroImage from "../assets/images/gastronomy-products.jpg";

const Produtos = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const PER_PAGE = 15;
  const [page, setPage] = useState(1);
  const {
    data: products,
    isLoaded: loadedProducts,
    pagination: productTotals,
  } = useApiData(
    `${Settings.server}/api/v1/product?lang=${language}&per-page=${PER_PAGE}&page=${page}`
  );

  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  useEffect(() => {
    setTotalPages(productTotals.page);
    setTotalProducts(productTotals.total);
  }, [productTotals]);

  return (
    <>
      <NavBar active="gastronomia" />

      {loadedProducts ? (
        <main className="bg-gray">
          <div
            className="relative bg-local bg-center bg-cover h-72 sm:h-80 md:h-heading"
            style={{
              backgroundImage: `url(${heroImage})`,
            }}
          >
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-overlay-4">
              <h1 className="text-4xl text-center text-white sm:text-5xl md:text-6xl lg:text-7xl font-reg sm:font-reg-light">
                {t("labels.products")}
              </h1>
            </div>
          </div>

          <div className="grid w-10/12 grid-cols-1 py-10 mx-auto md:py-20 sm:grid-cols-2 lg:grid-cols-3 gap-7 lg:w-products shadow-filter">
            {products.length > 0 && (
              products.map((product) => (
                <CardProduto key={product.id} product={product} />
              ))
            )}
          </div>

          {totalPages > 1 && totalProducts > PER_PAGE && (
            <div className="pb-10 mx-auto w-products">
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                handlePageChange={setPage}
              />
            </div>
          )}
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default Produtos;
