import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import SocialShare from "../components/SocialShare";
import Loading from "../components/Loading";
import Related from "../components/Related";
import ImagesSlider from "../components/ImagesSlider";
import Map from "../components/Map";

const addMarker = (map, link) => {
  return new window.google.maps.Marker({
    map,
    position: link.coords,
  });
};

const ProdutorDetalhe = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { slug } = useParams();
  let history = useHistory();

  const { data: producer, isLoaded: loaded, isError } = useApiData(
    `${Settings.server}/api/v1/producer/${slug}?expand=media&lang=${language}`
  );

  if (isError) {
    history.push(`/${language}/${t("slugs.makers")}/404`);
  }

  const [related, setRelated] = useState([]);
  const {
    data: relatedProducers,
    isLoaded: relatedProducersLoaded,
    isError: relatedProducersError,
  } = useApiData(
    `${Settings.server}/api/v1/producer?per-page=5&lang=${language}`
  );

  useEffect(() => {
    if (relatedProducersLoaded && !relatedProducersError) {
      let producersList = relatedProducers.filter((e) => e.slug !== slug);
      if (producersList.length > 4) {
        producersList.pop();
      }
      setRelated(producersList);
    }
  }, [relatedProducers, slug, relatedProducersLoaded, relatedProducersError]);

  return (
    <>
      <NavBar active="gastronomia" />

      {loaded ? (
        <main className="bg-gray">
          <div
            className="relative flex items-center justify-center bg-local bg-center bg-cover h-detail-heading"
            style={{
              backgroundImage: producer.media?.hasOwnProperty("5")
                ? `url(${producer.media["5"][0]?.path})`
                : "none",
            }}
          >
            <div className="absolute top-0 left-0 w-full h-full bg-overlay-2">
              <div className="flex flex-col items-center justify-center h-full mx-auto text-white lg:items-start lg:w-reg-grid">
                <div className="w-10/12 text-white lg:w-1/2">
                  <h1 className="text-3xl leading-8 text-center uppercase lg:text-left lg:text-5xl lg:leading-14 font-reg-bold">
                    {producer.name}
                  </h1>

                  <div className="flex-row invisible hidden pt-7 lg:flex lg:visible">
                    {producer.schedule && (
                      <div className="w-1/2 mr-4">
                        <p className="text-base font-reg-bold">
                          {t("labels.schedule")}:
                        </p>
                        <p className="text-sm font-reg-light">
                          {producer.schedule}
                        </p>
                      </div>
                    )}
                    <div className="w-1/2 mr-4">
                      <p className="text-base font-reg-bold">
                        {t("labels.contacts")}:
                      </p>
                      <p className="text-sm font-reg-light">
                        {t("labels.phone")} - <span>{producer.contact}</span>
                      </p>
                      {producer.email && (
                        <p className="text-sm font-reg-light">
                          E-mail -{" "}
                          <a href={`mailto:${producer.email}`}>
                            {producer.email}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col visible block w-10/12 mx-auto sm:flex-row pt-7 lg:hidden lg:invisible text-blue">
            {producer.schedule && (
              <div className="mr-4 sm:w-1/2">
                <p className="text-base font-reg-bold">
                  {t("labels.schedule")}:
                </p>
                <p className="text-sm font-reg-light">{producer.schedule}</p>
              </div>
            )}
            <div className="mt-4 sm:mt-0 sm:mr-4 sm:w-1/2">
              <p className="text-base font-reg-bold">{t("labels.contacts")}:</p>
              <p className="text-sm font-reg-light">
                {t("labels.phone")} - <span>{producer.contact}</span>
              </p>
              {producer.email && (
                <p className="text-sm font-reg-light">
                  E-mail -{" "}
                  <a href={`mailto:${producer.email}`}>{producer.email}</a>
                </p>
              )}
            </div>
          </div>

          <section className="w-10/12 py-10 mx-auto lg:w-reg-grid lg:py-22">
            <div className="flex flex-col flex-col-reverse lg:flex-row">
              <div className="ml-0 lg:-ml-25 text-blue">
                <SocialShare />
              </div>

              <div className="w-1/12" />

              <div className="flex flex-col lg:w-11/12 lg:flex-row">
                <div className="flex flex-col lg:w-2/3">
                  <h2 className="pb-4 text-2xl lg:pb-10 lg:text-3xl text-blue font-reg-bold header-prefix">
                    {t("labels.aboutMaker")}
                  </h2>

                  <div
                    className="pb-10 text-base text-blue product-content font-reg-light"
                    dangerouslySetInnerHTML={{
                      __html: producer.description,
                    }}
                  />

                  <div className="flex flex-row flex-wrap justify-around w-full sm:pb-4 sm:justify-start lg:pb-10">
                    <dl className="pb-4 text-sm sm:pb-0 sm:mr-12 text-blue">
                      <dt className="font-reg-bold">{t("labels.county")} :</dt>
                      <dd className="font-reg-light">{producer.country}</dd>
                    </dl>
                    <dl className="pb-4 text-sm sm:pb-0 sm:mr-12 text-blue">
                      <dt className="font-reg-bold">{t("labels.website")} :</dt>
                      {producer.website && (
                        <dd className="font-reg-light">
                          <a
                            href={producer.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:border-b hover:border-blue"
                          >
                            {producer.website.replace(/(^\w+:|^)\/\//, "")}
                          </a>
                        </dd>
                      )}
                    </dl>
                  </div>

                  {(producer.social_networks?.facebook ||
                    producer.social_networks?.instagram) && (
                    <div className="flex flex-row flex-wrap justify-around pt-4 pb-10 border-t lg:pb-0 sm:justify-start sm:pt-7 border-cool-gray-200">
                      {producer.social_networks?.facebook && (
                        <div className="flex flex-row items-center justify-center mr-6 text-sm text-blue font-reg-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-6 h-6"
                          >
                            <defs />
                            <path
                              fill="currentColor"
                              d="M17.525 9H14V7c0-1.032.084-1.682 1.563-1.682h1.868v-3.18c-.909-.094-1.823-.14-2.738-.138C11.98 2 10 3.657 10 6.699V9H7v4l3-.001V22h4v-9.003l3.066-.001L17.525 9z"
                            />
                          </svg>

                          <a
                            href={producer.social_networks.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {producer.social_networks_label.facebook}
                          </a>
                        </div>
                      )}
                      {producer.social_networks?.instagram && (
                        <div className="flex flex-row items-center justify-center mr-6 text-sm text-blue font-reg-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            className="w-6 h-6 mr-2"
                          >
                            <defs />
                            <path
                              fill="currentColor"
                              d="M5.8 0h8.4C17.4 0 20 2.6 20 5.8v8.4c0 3.2-2.6 5.8-5.8 5.8H5.8C2.6 20 0 17.4 0 14.2V5.8C0 2.6 2.6 0 5.8 0m-.2 2C3.6 2 2 3.6 2 5.6v8.8c0 2 1.6 3.6 3.6 3.6h8.8c2 0 3.6-1.6 3.6-3.6V5.6c0-2-1.6-3.6-3.6-3.6H5.6m9.7 1.5c.7 0 1.3.6 1.3 1.3S15.9 6 15.3 6 14 5.4 14 4.8s.6-1.3 1.3-1.3M10 5c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5m0 2c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"
                            />
                          </svg>

                          <a
                            href={producer.social_networks.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {producer.social_networks_label.instagram}
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="lg:w-1/3 lg:ml-7">
                  <Map
                    options={{
                      center: {
                        lat: parseFloat(producer?.coordinates?.lat),
                        lng: parseFloat(producer?.coordinates?.lon),
                      },
                      zoom: 15,
                      disableDefaultUI: true,
                    }}
                    onMount={addMarker}
                    onMountProps={{
                      coords: {
                        lat: parseFloat(producer?.coordinates?.lat),
                        lng: parseFloat(producer?.coordinates?.lon),
                      },
                    }}
                  />
                  <div className="pt-4 text-sm text-blue">
                    <div className="mb-2">
                      <p className="font-reg-bold">{t("labels.location")}</p>
                      <p className="leading-5 font-reg-light">
                        {producer.address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {producer.media?.hasOwnProperty("2") &&
            producer.media["2"].length > 0 && (
              <ImagesSlider
                images={producer.media["2"]}
                label={producer.name}
              />
            )}

          {relatedProducersLoaded && related.length > 0 && (
            <section className="pt-8 pb-8 md:pt-16 md:pb-16 lg:pt-20 lg:pb-20">
              <div className="mx-auto md:max-w-reg-grid">
                <Related type="producer" items={related} columns={4} shuffle />
              </div>
            </section>
          )}
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default ProdutorDetalhe;
