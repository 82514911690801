import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardEvento = ({ event, slider = false }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  let linkClass = "overflow-hidden bg-white shadow-card hover:shadow-md ";

  linkClass += slider
    ? "block sm:w-72 lg:w-full my-2 lg:my-0 mx-4 sm:mx-auto h-72 sm:h-auto"
    : "mx-auto block w-full sm:w-96 xl:w-full";

  /*if (event.url) {
    return (
      <a
        href={`//${event.url}`}
        className={linkClass}
        target="_blank"
        rel="noopener noreferrer"
      >
        <CardContent event={event} />
      </a>
    );
  }*/

  return (
    <Link
      to={`/${language}/${t("slugs.events")}/${event.id}`}
      className={linkClass}
    >
      <div className={linkClass}>
        <CardContent event={event} t={t} />
      </div>
    </Link>
  );
};

const CardContent = ({ event,t }) => (
  <>
    <div className="relative pb-2/3">
      {event.media?.hasOwnProperty("8") && (
        <img
          className="absolute object-cover w-full h-full"
          src={event.media["8"]?.path}
          alt={event.title}
        />
      )}
    </div>
    <div className="px-4 pt-4 pb-5">
      <h4 className="pb-3 text-base leading-4 min-h-12 text-blue font-reg">
        {event.title}
      </h4>
      <div className="flex flex-row items-center text-gray-dark">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 13 14"
          className="w-4 h-4 mr-2 text-blue"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.00000119 13.0000155h2.25000268v-2.2500027H1.00000119v2.2500027zm2.75000328 0h2.5v-2.2500027h-2.5v2.2500027zm-2.75000328-2.7500063h2.25000268V7.75000924H1.00000119v2.49999996zm2.75000328 0h2.5V7.75000924h-2.5v2.49999996zM1.00000119 7.25000864h2.25000268V5.00000596H1.00000119v2.25000268zm5.75000686 5.75000686h2.5v-2.2500027h-2.5v2.2500027zM3.75000447 7.25000864h2.5V5.00000596h-2.5v2.25000268zm6.00000715 5.75000686h2.25000268v-2.2500027H9.75001162v2.2500027zm-3.00000357-2.7500063h2.5V7.75000924h-2.5v2.49999996zM4.00000358 3.50000119v-2.2499997c0-.13281266-.11718645-.2500003-.24999911-.2500003h-.5000006c-.13281265 0-.25000029.11718764-.25000029.2500003v2.2499997c0 .13281564.11718764.25000328.25000029.25000328h.5000006c.13281266 0 .24999911-.11718764.24999911-.25000328zm5.75000804 6.75000801h2.25000268V7.75000924H9.75001162v2.49999996zM6.75000805 7.25000864h2.5V5.00000596h-2.5v2.25000268zm3.00000357 0h2.25000268V5.00000596H9.75001162v2.25000268zm.24999908-3.75000745v-2.2499997c0-.13281266-.11718642-.2500003-.24999908-.2500003h-.50000059c-.13281266 0-.2500003.11718764-.2500003.2500003v2.2499997c0 .13281564.11718764.25000328.2500003.25000328h.50000059c.13281266 0 .24999908-.11718764.24999908-.25000328zM13.0000155 3v10.0000155c0 .5468756-.4531255 1.0000012-1.0000012 1.0000012H1c-.54687446 0-1-.4531256-1-1.0000012V3c0-.54687208.45312554-1 1-1h1v-.74999851C2 .56250067 2.56250305 0 3.25000387 0h.5000006C4.43750529 0 5 .56250067 5 1.25000149V2h3v-.74999851C8 .56250067 8.56251021 0 9.25001103 0h.50000059c.68750078 0 1.25000148.56250067 1.25000148 1.25000149V2h1.0000012c.5468757 0 1.0000012.45312792 1.0000012 1z"
          />
        </svg>
        <span className="text-sm font-reg-light">{event.date_string}</span>
      </div>
      <div className="flex flex-row items-center text-gray-dark">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 8 12"
          className="w-4 h-4 mr-2 text-blue"
        >
          <defs />
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.00000238 4.0000024c0-1.1015614-.8984338-2-2-2-1.10156142 0-2 .8984386-2 2 0 1.1015662.89843858 2 2 2 1.1015662 0 2-.8984338 2-2zM8 4c0 .4765678-.054678.9687559-.2578033 1.3984439l-2.84375336 6.0468822c-.1640627.3437505-.52343812.5546882-.89844334.5546882-.37499568 0-.73437111-.2109377-.89062129-.5546882L.25781281 5.3984439C.05468757 4.9687559 0 4.4765678 0 4c0-2.2109354 1.78906463-4 4-4 2.2109449 0 4 1.7890646 4 4z"
          />
        </svg>
        <span className="text-sm font-reg-light">{event.location}</span>
      </div>
      <div className="flex justify-end">
          <div className="flex flex-row items-center h-6 mt-2 mb-4 text-sm leading-3 uppercase sm:mt-4 sm:mb-6 sm:text-base font-reg text-blue">
            <span className="bg-bottom bg-no-repeat underline-reg underline-reg-blue">
              {t("labels.read")}
            </span>
            <div className="w-4 h-1 ml-2 text-blue min-w-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 3"
                className="fill-current"
              >
                <defs />
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                />
              </svg>
            </div>
          </div>
        </div>
    </div>
  </>
);

export default CardEvento;
