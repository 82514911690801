import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Breakpoint } from "react-socks";

import Checkbox from "./FiltersCheckbox";
import FiltersMobile from "./FiltersMobile";

const Filters = ({
  items,
  handleFiltersChange,
  handleFiltersUpdate,
  handleFiltersClear,
  handleApplyFilters,
}) => {
  const { t } = useTranslation();

  const [showClearFilters, setShowClearFilters] = useState(false);
  useEffect(() => {
    const fnInc = (p, c) => {
      if (c.id !== 0 && c.checked) {
        return p + 1;
      }
      return p;
    };

    const checkedItems = items
      .map((item) => item.content.reduce(fnInc, 0))
      .reduce((p, c) => p + c, 0);

    if (checkedItems) {
      setShowClearFilters(true);
    }
  }, [items]);

  return (
    <>
      <Breakpoint customQuery="(max-width: 1023px)">
        <div className="flex flex-row items-center justify-end">
          {showClearFilters && (
            <button
              type="button"
              className="mr-4 text-sm underline text-blue font-reg-light"
              onClick={handleFiltersClear}
            >
              {t("labels.filtersClear")}
            </button>
          )}
          <FiltersMobile
            items={items}
            handleFiltersChange={handleFiltersUpdate}
            handleFiltersClear={handleFiltersClear}
            handleApplyFilters={handleApplyFilters}
          />
        </div>
      </Breakpoint>
      <Breakpoint customQuery="(min-width: 1024px)">
        <div className="flex items-center justify-between pb-2 shadow-filter">
          <h5 className="text-lg uppercase font-reg text-gray-dark">
            {t("labels.filters")}
          </h5>
          {showClearFilters && (
            <button
              type="button"
              className="text-sm text-blue font-reg-light"
              onClick={handleFiltersClear}
            >
              {t("labels.filtersClear")}
            </button>
          )}
        </div>

        <div className="pt-5">
          {items.map((filter, i) => (
            <div key={i} className="pb-4">
              <h6 className="pb-5 text-base text-gray-dark font-reg-bold">
                {filter.title}
              </h6>
              <div className="flex flex-col">
                {filter.content.map((item) => (
                  <div className="mb-3" key={item.id}>
                    <Checkbox
                      value={item.id}
                      label={item.value}
                      checked={item.checked}
                      type={items.length > 1 ? filter.type : false}
                      onChange={handleFiltersChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Breakpoint>
    </>
  );
};

export default Filters;
