import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";

import useTracking from "./hooks/useTracking";

import RouteGastronomia from "./routes/gastronomia";
import RouteAcontece from "./routes/acontece";

import Home from "./pages/Home";
import Contactos from "./pages/Contactos";
import NotFound from "./pages/404";
import Pesquisa from "./pages/Pesquisa";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import Projeto from "./pages/Projeto";
import TermosCondicoes from "./pages/TermosCondicoes";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RouteTracker = () => {
  useTracking("UA-170283041-1");
  return null;
}

const Routing = () => {
  return (
    <BrowserRouter>
      <RouteTracker />
      <ScrollToTop />
      <Switch>
        <Route path={`/:lang?`} exact component={Home} />

        <Route path="/pt/projeto" component={Projeto} />
        <Route path="/en/project" component={Projeto} />

        <Route path="/pt/gastronomia" component={RouteGastronomia} />
        <Route path="/en/gastronomy" component={RouteGastronomia} />

        <Route path="/pt/acontece" component={RouteAcontece} />
        <Route path="/en/happening" component={RouteAcontece} />

        <Route path="/pt/contactos" component={Contactos} />
        <Route path="/en/contacts" component={Contactos} />

        <Route path="/pt/pesquisa" component={Pesquisa} />
        <Route path="/en/search" component={Pesquisa} />

        <Route path="/pt/termos-condições" component={TermosCondicoes} />
        <Route path="/en/terms-conditions" component={TermosCondicoes} />

        <Route
          path="/pt/política-privacidade"
          component={PoliticaPrivacidade}
        />
        <Route path="/en/privacy-policy" component={PoliticaPrivacidade} />

        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routing;
