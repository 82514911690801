import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import heroNews from "../assets/images/news-hero.png";
import heroEvents from "../assets/images/events-hero.png";

const Acontece = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { data: content, isLoaded: loaded } = useApiData(
    `${Settings.server}/api/v1/institutional?page_url=happening&lang=${language}`
  );

  return (
    <>
      <NavBar active="acontece" />

      <main className="bg-gray">
        <h1 className="pt-10 pb-4 text-5xl text-center sm:text-6xl text-blue md:pt-26 md:text-7xl font-reg sm:font-reg-light">
          {t("labels.happening")}
        </h1>

        <div
          className="px-8 text-sm leading-7 text-center sm:text-base font-reg-light text-blue pb-14"
          dangerouslySetInnerHTML={{
            __html: loaded
              ? content.find((item) => item.section === "subtitle")?.content
              : "",
          }}
        />

        <div className="grid grid-cols-1 px-8 pb-20 mx-auto lg:grid-cols-2 lg:w-reg-grid gap-7 lg:px-25 sm:w-125">
          <Link to={`/${language}/${t("slugs.news")}`}>
            <div
              className="relative bg-center bg-cover h-card-image"
              style={{
                backgroundImage: `url(${heroNews})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-6 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <h4 className="pb-5 text-2xl leading-8 text-white font-reg-bold header-prefix">
                  {t("labels.news")}
                </h4>
                <div className="flex flex-row items-center pb-8 text-base text-white uppercase font-reg">
                  <span className="bg-bottom bg-no-repeat underline-reg-small underline-reg-white">
                    {t("labels.explore")}
                  </span>
                  <div className="w-4 h-1 ml-2 text-white min-w-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 3">
                      <defs />
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to={`/${language}/${t("slugs.events")}`}>
            <div
              className="relative bg-center bg-cover h-card-image"
              style={{
                backgroundImage: `url(${heroEvents})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-6 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <h4 className="pb-5 text-2xl leading-8 text-white font-reg-bold header-prefix">
                  {t("labels.events")}
                </h4>
                <div className="flex flex-row items-center pb-8 text-base text-white uppercase font-reg">
                  <span className="bg-bottom bg-no-repeat underline-reg-small underline-reg-white">
                    {t("labels.explore")}
                  </span>
                  <div className="w-4 h-1 ml-2 text-white min-w-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 3">
                      <defs />
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Acontece;
