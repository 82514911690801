import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardExperiencia = ({ tour, slider = false }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const linkClass =
    "overflow-hidden bg-white shadow-card hover:shadow-md gastronomy-card lg:w-full " +
    (slider
      ? "block sm:w-72 my-2 lg:my-0 mx-4 sm:mx-auto h-72 sm:h-auto"
      : "w-64 mx-auto sm:w-80 md:w-72");

  return (
    <Link
      to={`/${language}/${t("slugs.tours")}/${tour.slug}`}
      className={linkClass}
    >
      <div className="relative bg-white pb-4/5">
        {tour.media?.hasOwnProperty("14") && (
          <img
            className="absolute object-cover w-full h-full transition-all duration-500 ease-in-out"
            src={tour.media["14"].path}
            alt={tour.name}
          />
        )}
      </div>
      <div className="px-4 pt-4 pb-5 bg-white">
        <h4 className="pb-3 text-base leading-4 text-blue font-reg">
          {tour.name}
        </h4>
        <div className="flex flex-row items-center text-gray-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="w-4 h-4 mr-2"
          >
            <defs />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M6.00000238 4.0000024c0-1.1015614-.8984338-2-2-2-1.10156142 0-2 .8984386-2 2 0 1.1015662.89843858 2 2 2 1.1015662 0 2-.8984338 2-2zM8 4c0 .4765678-.054678.9687559-.2578033 1.3984439l-2.84375336 6.0468822c-.1640627.3437505-.52343812.5546882-.89844334.5546882-.37499568 0-.73437111-.2109377-.89062129-.5546882L.25781281 5.3984439C.05468757 4.9687559 0 4.4765678 0 4c0-2.2109354 1.78906463-4 4-4 2.2109449 0 4 1.7890646 4 4z"
            />
          </svg>
          <span className="text-sm font-reg-light">{tour.country}</span>
        </div>
        <div className="flex flex-row items-center mt-1 text-gray-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            className="w-4 h-4 mr-2"
          >
            <defs />
            <path
              fill="currentColor"
              d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"
            />
          </svg>
          <span className="text-sm font-reg-light">{tour.category}</span>
        </div>
      </div>
    </Link>
  );
};

export default CardExperiencia;
