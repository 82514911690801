let server;

switch (process.env.NODE_ENV) {
  case "development":
    server = "https://api.tastecoimbraregion.pt";
    break;
  case "qa":
    server = "https://api.tastecoimbraregion.pt";
    break;
  case "production":
    server = "https://api.tastecoimbraregion.pt";
    break;
  default:
    server = "https://api.tastecoimbraregion.pt";
    break;
}

export const SERVER = server;
