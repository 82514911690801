import { useState, useEffect } from "react";

const useFilters = (items, label, urlParamPrefix) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemsParam, setItemsParam] = useState("");

  useEffect(() => {
    if (items.length) {
      const first = [
        {
          id: 0,
          value: label,
          checked: true,
        },
      ];
      const c = items.map((item) => ({
        id: item.id,
        value: item.name,
        checked: false,
      }));
      setSelectedItems(first.concat(...c));
    }
  }, [items, label]);

  const resetSelectedItems = () => {
    return selectedItems.map((item) => {
      if (item.id === 0) {
        return {
          ...item,
          checked: true,
        };
      }
      return { ...item, checked: false };
    });
  };

  const updateSelectedItems = (value, checked) => {
    const checkedItems = selectedItems.reduce((previous, current) => {
      if (current.checked) {
        return previous + 1;
      }
      return previous;
    }, 0);

    return selectedItems.map((item) => {
      if (item.id === 0) {
        if (checked && checkedItems === 1) {
          return { ...item, checked: true };
        }
        return { ...item, checked: false };
      }
      if (item.id === value) {
        if (checked) {
          return { ...item, checked: false };
        }
        return { ...item, checked: true };
      }
      return { ...item };
    });
  };

  const handleFilterChange = (value, checked) => {
    let items = [];
    if (value === 0) {
      items = resetSelectedItems();
    } else {
      items = updateSelectedItems(value, checked);
    }
    setSelectedItems(items);
    setItemsParam(concatParams(items));
  };

  const handleFilterUpdate = (value, checked) => {
    let items = [];
    if (value === 0) {
      items = resetSelectedItems();
    } else {
      items = updateSelectedItems(value, checked);
    }
    setSelectedItems(items);
  };

  const handleApplyFilter = () => {
    setItemsParam(concatParams(selectedItems));
  };

  const handleFilterReset = () => {
    const items = resetSelectedItems();
    setSelectedItems(items);
    setItemsParam(concatParams(items));
  };

  const concatParams = (items) => {
    let paramUrl = "";
    items.forEach((item) => {
      if (item.id !== 0 && item.checked) {
        paramUrl += urlParamPrefix + item.id;
      }
    });
    return paramUrl;
  };

  return {
    params: itemsParam,
    selected: selectedItems,
    change: handleFilterChange,
    update: handleFilterUpdate,
    apply: handleApplyFilter,
    reset: handleFilterReset,
  };
};

export default useFilters;
