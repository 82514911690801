import React from "react";
import { Switch, Route } from "react-router-dom";

import Receitas from "../pages/Receitas";
import ReceitaDetalhe from "../pages/ReceitaDetalhe";
import NotFound from "../pages/404";

const RouteReceitas = () => {
  return (
    <Switch>
      <Route path="/pt/gastronomia/receitas" exact component={Receitas} />
      <Route path="/pt/gastronomia/receitas/404" exact component={NotFound} />
      <Route path="/pt/gastronomia/receitas/:slug" component={ReceitaDetalhe} />

      <Route path="/en/gastronomy/recipes" exact component={Receitas} />
      <Route path="/en/gastronomy/recipes/404" exact component={NotFound} />
      <Route path="/en/gastronomy/recipes/:slug" component={ReceitaDetalhe} />
    </Switch>
  );
};

export default RouteReceitas;
