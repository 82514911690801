import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Settings from "../helpers/settings";
import shuffle from "../helpers/shuffle";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import CardPesquisa from "../components/CardPesquisa";

import heroImage from "../assets/images/project-region-right.jpg";

const Pesquisa = () => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState("all");
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const handleCategoryChange = (e) => setCategory(e.target.value);
  const handleSearchInputChanges = (e) => setQuery(e.target.value);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setShowResults(true);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [category]);

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    let currentQuery = true;

    const fetchRestaurants = async (query) => {
      await sleep(400);
      if (currentQuery) {
        const result = await axios(
          `${Settings.server}/api/v1/restaurant?name=${query}`
        );
        const parsedRestaurants = result.data.map((r) => ({
          ...r,
          type: "restaurant",
        }));
        setResults(parsedRestaurants);
      }
    };

    const fetchRecipes = async (query) => {
      await sleep(400);
      if (currentQuery) {
        const result = await axios(
          `${Settings.server}/api/v1/recipe?name=${query}`
        );
        const parsedRecipes = result.data.map((r) => ({
          ...r,
          type: "recipe",
        }));
        setResults(parsedRecipes);
      }
    };

    const fetchArticles = async (query) => {
      await sleep(400);
      if (currentQuery) {
        const result = await axios(
          `${Settings.server}/api/v1/article?title=${query}`
        );
        const parsedArticles = result.data.map((r) => ({
          ...r,
          type: "article",
        }));
        setResults(parsedArticles);
      }
    };

    const fetchEvents = async (query) => {
      await sleep(400);
      if (currentQuery) {
        const result = await axios(
          `${Settings.server}/api/v1/event?title=${query}`
        );
        const parsedEvents = result.data.map((r) => ({
          ...r,
          type: "event",
        }));
        setResults(parsedEvents);
      }
    };

    const fetchEverything = async (query) => {
      await sleep(400);
      if (currentQuery) {
        const restaurantResult = await axios(
          `${Settings.server}/api/v1/restaurant?name=${query}`
        );
        const parsedRestaurants = restaurantResult.data.map((r) => ({
          ...r,
          type: "restaurant",
        }));

        const recipeResult = await axios(
          `${Settings.server}/api/v1/recipe?name=${query}`
        );
        const parsedRecipes = recipeResult.data.map((r) => ({
          ...r,
          type: "recipe",
        }));

        const articleResult = await axios(
          `${Settings.server}/api/v1/article?title=${query}`
        );
        const parsedArticles = articleResult.data.map((r) => ({
          ...r,
          type: "article",
        }));

        const eventResult = await axios(
          `${Settings.server}/api/v1/event?title=${query}`
        );
        const parsedEvents = eventResult.data.map((r) => ({
          ...r,
          type: "event",
        }));
        const all = parsedRestaurants
          .concat(parsedRecipes)
          .concat(parsedArticles)
          .concat(parsedEvents);

        setResults(shuffle(all));
      }
    };

    if (query.length > 2) {
      switch (category) {
        case "all":
          fetchEverything(query);
          break;
        case "restaurant":
          fetchRestaurants(query);
          break;
        case "recipe":
          fetchRecipes(query);
          break;
        case "article":
          fetchArticles(query);
          break;
        case "event":
          fetchEvents(query);
          break;
        default:
          break;
      }
    }

    return () => {
      currentQuery = false;
      setShowResults(false);
    };
  }, [query, category]);

  return (
    <>
      <NavBar />

      <main className="bg-white">
        <div
          className="relative flex items-center justify-center bg-local bg-center bg-cover h-72 sm:h-80 md:h-heading"
          style={{
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-overlay-3">
            <h1 className="text-4xl text-center text-white sm:text-5xl md:text-6xl lg:text-7xl font-reg sm:font-reg-light">
              {t("labels.search")}
            </h1>
          </div>
        </div>

        <section className="w-10/12 pt-20 pb-32 mx-auto lg:w-reg-grid">
          <p className="pb-10 text-base leading-7 text-center uppercase font-reg-light text-blue">
            {t("labels.searchSubtitle")}
          </p>

          <div className="w-full mx-auto md:w-10/12 lg:w-8/12 pb-11">
            <div className="justify-center invisible hidden md:flex md:visible">
              <span className="relative z-0 inline-flex w-full rounded font-reg-light text-blue">
                <div className="relative inline-flex items-center w-1/2">
                  <div className="absolute right-0 pr-5 pointer-events-none">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-4 h-4"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </div>
                  <input
                    className="w-full py-6 pl-4 pr-10 text-sm leading-4 border rounded-none rounded-l outline-none form-input border-blue focus:border-blue focus:shadow-none focus:outline-none"
                    type="text"
                    ref={inputRef}
                    autoFocus
                    placeholder={t("labels.searchPlaceholder")}
                    onBlur={(e) =>
                      (e.target.placeholder = t("labels.searchPlaceholder"))
                    }
                    onFocus={(e) => (e.target.placeholder = "")}
                    onChange={handleSearchInputChanges}
                    onKeyDown={handleKeyPress}
                    value={query}
                  />
                </div>
                <div className="relative inline-block w-1/4">
                  <select
                    className="relative inline-flex items-center block w-full h-full pl-4 text-sm leading-4 bg-white border-t border-b border-l-0 rounded-none outline-none appearance-none border-blue"
                    onChange={handleCategoryChange}
                  >
                    <option value="all">{t("labels.allCategories")}</option>
                    <option value="event">{t("labels.events")}</option>
                    <option value="article">{t("labels.news")}</option>>
                    <option value="recipe">{t("labels.recipes")}</option>>
                    <option value="restaurant">
                      {t("labels.restaurants")}
                    </option>
                    >
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none text-blue">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 12 12"
                      className="w-4 h-3"
                    >
                      <defs />
                      <path fill="currentColor" d="M0 3l6 6 6-6H0z" />
                    </svg>
                  </div>
                </div>
                <button
                  onClick={() => setShowResults(true)}
                  type="button"
                  className="relative inline-flex items-center justify-center w-1/4 text-lg text-white rounded-r bg-blue font-reg hover:bg-transparent hover:text-blue hover:border-blue hover:border"
                >
                  {t("labels.searchButton")}
                </button>
              </span>
            </div>
            <div className="flex flex-col visible w-full md:invisible md:hidden text-blue font-reg-light">
              <div className="relative inline-flex items-center w-full pb-4 mx-auto sm:w-2/3">
                <div className="absolute right-0 pr-5 pointer-events-none">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-4 h-4"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </div>
                <input
                  className="w-full py-4 pl-4 pr-10 text-sm leading-4 border rounded outline-none form-input border-blue focus:border-blue focus:shadow-none focus:outline-none"
                  type="text"
                  ref={inputRef}
                  autoFocus
                  placeholder={t("labels.searchPlaceholder")}
                  onBlur={(e) =>
                    (e.target.placeholder = t("labels.searchPlaceholder"))
                  }
                  onFocus={(e) => (e.target.placeholder = "")}
                  onChange={handleSearchInputChanges}
                  onKeyDown={handleKeyPress}
                  value={query}
                />
              </div>
              <div className="relative inline-block w-full mx-auto sm:w-2/3">
                <select
                  className="relative inline-flex items-center block w-full h-full py-4 pl-4 text-sm leading-4 bg-white border rounded outline-none appearance-none border-blue"
                  onChange={handleCategoryChange}
                >
                  <option value="all">{t("labels.allCategories")}</option>
                  <option value="event">{t("labels.events")}</option>
                  <option value="article">{t("labels.news")}</option>>
                  <option value="recipe">{t("labels.recipes")}</option>>
                  <option value="restaurant">{t("labels.restaurants")}</option>>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none text-blue">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 12"
                    className="w-4 h-3"
                  >
                    <defs />
                    <path fill="currentColor" d="M0 3l6 6 6-6H0z" />
                  </svg>
                </div>
              </div>
              <button
                onClick={() => setShowResults(true)}
                type="button"
                className="relative inline-flex items-center justify-center w-full py-4 mx-auto mt-4 text-lg text-white rounded sm:w-2/3 bg-blue font-reg hover:bg-transparent hover:text-blue hover:border-blue hover:border"
              >
                {t("labels.searchButton")}
              </button>
            </div>
          </div>

          <div className="w-full mx-auto md:w-10/12">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              {showResults &&
                results.length > 0 &&
                results.map((item, i) => <CardPesquisa key={i} item={item} />)}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Pesquisa;
