import React from "react";
import { Switch, Route } from "react-router-dom";

import Acontece from "../pages/Acontece";
import NotFound from "../pages/404";

import RouteNoticias from "../routes/noticias";
import RouteEventos from "../routes/eventos";
import RotaMercados from "../routes/rotamercados";

const RouteAcontece = () => {
  return (
    <Switch>
      <Route path="/pt/acontece" exact component={Acontece} />
      <Route path="/en/happening" exact component={Acontece} />

      <Route path={`/pt/acontece/notícias`} component={RouteNoticias} />
      <Route path={`/en/happening/news`} component={RouteNoticias} />

      <Route path={`/pt/acontece/eventos`}  component={RouteEventos} />
      <Route path={`/en/happening/events`}  component={RouteEventos} />

      <Route path={`/pt/acontece/rota-mercados`}  component={RotaMercados} />
      <Route path={`/en/happening/rota-mercados`}  component={RotaMercados} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default RouteAcontece;
