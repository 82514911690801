import React from "react";
import { Switch, Route } from "react-router-dom";

import Experiencias from "../pages/Experiencias";
import ExperienciaDetalhe from "../pages/ExperienciaDetalhe";
import NotFound from "../pages/404";

const RouteExperiencias = () => {
  return (
    <Switch>
      <Route path="/pt/gastronomia/experiências" exact component={Experiencias} />
      <Route path="/pt/gastronomia/experiências/404" exact component={NotFound} />
      <Route path="/pt/gastronomia/experiências/:slug" component={ExperienciaDetalhe} />

      <Route path="/en/gastronomy/experiences" exact component={Experiencias} />
      <Route path="/en/gastronomy/experiences/404" exact component={NotFound} />
      <Route path="/en/gastronomy/experiences/:slug" component={ExperienciaDetalhe} />
    </Switch>
  );
};

export default RouteExperiencias;
