import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";
import useFilters from "../hooks/useFilters";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import Filters from "../components/Filters";

import heroImage from "../assets/images/gastronomy-restaurants.jpg";
import CardRestaurante from "../components/CardRestaurante";

const Restaurantes = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { data: countries, isLoaded: loadedCountries } = useApiData(
    `${Settings.server}/api/v1/country?lang=${language}`
  );

  const {
    params: countriesParam,
    selected: selectedCountries,
    change: handleCountryChange,
    apply: handleApplyCountry,
    update: handleCountryUpdate,
    reset: resetCountries,
  } = useFilters(countries, t("labels.allCountries"), "&country_id[]=");

  const PER_PAGE = 15;
  const [page, setPage] = useState(1);

  const {
    data: restaurants,
    isLoaded: loadedRestaurants,
    pagination: restaurantTotals,
  } = useApiData(
    `${Settings.server}/api/v1/restaurant?sort=name&per-page=${PER_PAGE}&page=${page}${countriesParam}`
  );

  const [totalPages, setTotalPages] = useState(0);
  const [totalRestaurants, setTotalRestaurants] = useState(0);
  useEffect(() => {
    setTotalPages(restaurantTotals.page);
    setTotalRestaurants(restaurantTotals.total);
  }, [restaurantTotals]);

  const onApplyFilters = () => {
    setPage(1);
    handleApplyCountry();
  };

  const [filters, setFilters] = useState([]);
  useEffect(() => {
    setFilters([
      {
        type: "country",
        title: t("labels.countries"),
        content: selectedCountries,
      },
    ]);
  }, [selectedCountries, t]);

  return (
    <>
      <NavBar active="gastronomia" />

      {loadedCountries && loadedRestaurants ? (
        <main className="pb-20 bg-gray">
          <div
            className="relative flex items-center justify-center bg-local bg-center bg-cover h-72 sm:h-80 md:h-heading"
            style={{
              backgroundImage: `url(${heroImage})`,
            }}
          >
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-overlay-4">
              <h1 className="text-4xl text-center text-white sm:text-5xl md:text-6xl lg:text-7xl font-reg sm:font-reg-light">
                {t("labels.restaurants")}
              </h1>
            </div>
          </div>

          <section className="flex flex-col w-10/12 pt-8 mx-auto md:flex-row md:pt-28 md:max-w-reg-grid">
            <div className="w-full pb-8 mr-0 md:pb-0 md:w-3/12 md:mr-7">
              <Filters
                items={filters}
                handleFiltersChange={handleCountryChange}
                handleFiltersUpdate={handleCountryUpdate}
                handleFiltersClear={resetCountries}
                handleApplyFilters={onApplyFilters}
              />
            </div>

            <div className="w-full md:w-9/12">
              {restaurants.length > 0 ? (
                <div className="grid grid-cols-1 gap-4 pb-10 sm:grid-cols-2 lg:grid-cols-3 shadow-filter">
                  {restaurants.map((restaurant) => (
                    <CardRestaurante
                      key={restaurant.id}
                      restaurant={restaurant}
                    />
                  ))}
                </div>
              ) : (
                <p className="pt-4 pb-8 text-xl text-center md:pb-0 md:text-2xl font-reg-bold text-blue">
                  {t("labels.noRestaurants")}
                </p>
              )}

              {totalPages > 1 && totalRestaurants > PER_PAGE && (
                <Pagination
                  currentPage={page}
                  totalPages={totalPages}
                  handlePageChange={setPage}
                />
              )}
            </div>
          </section>
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default Restaurantes;
