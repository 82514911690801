import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";
import useFilters from "../hooks/useFilters";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import Filters from "../components/Filters";

import heroImage from "../assets/images/producers-hero.png";
import CardProdutor from "../components/CardProdutor";

const Produtores = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { data: countries } = useApiData(
    `${Settings.server}/api/v1/country?lang=${language}`
  );

  const { data: categories } = useApiData(
    `${Settings.server}/api/v1/category?type=4&lang=${language}`
  );

  const {
    params: categoriesParam,
    selected: selectedCategories,
    change: handleCategoryChange,
    apply: handleApplyCategory,
    update: handleCategoryUpdate,
    reset: resetCategories,
  } = useFilters(categories, t("labels.allCategories"), "&category_id[]=");

  const {
    params: countriesParam,
    selected: selectedCountries,
    change: handleCountryChange,
    apply: handleApplyCountry,
    update: handleCountryUpdate,
    reset: resetCountries,
  } = useFilters(countries, t("labels.allCountries"), "&country_id[]=");

  const PER_PAGE = 15;
  const [page, setPage] = useState(1);
  const {
    data: producers,
    isLoaded: loadedProducers,
    pagination: producerTotals,
  } = useApiData(
    `${Settings.server}/api/v1/producer?lang=${language}&per-page=${PER_PAGE}&page=${page}${countriesParam}${categoriesParam}`
  );

  const [totalPages, setTotalPages] = useState(0);
  const [totalProducers, setTotalProducers] = useState(0);
  useEffect(() => {
    setTotalPages(producerTotals.page);
    setTotalProducers(producerTotals.total);
  }, [producerTotals]);

  const onFilterChange = (type, item, checked) => {
    switch (type) {
      case "category":
        handleCategoryChange(item, checked);
        break;
      case "country":
        handleCountryChange(item, checked);
        break;
      default:
        break;
    }
  };

  const onFilterUpdate = (type, value, checked) => {
    switch (type) {
      case "category":
        handleCategoryUpdate(value, checked);
        break;
      case "country":
        handleCountryUpdate(value, checked);
        break;
      default:
        break;
    }
  };

  const onFilterClear = () => {
    resetCountries();
    resetCategories();
  };

  const onApplyFilters = () => {
    setPage(1);
    handleApplyCategory();
    handleApplyCountry();
  };

  const [filters, setFilters] = useState([]);
  useEffect(() => {
    setFilters([
      {
        type: "country",
        title: t("labels.countries"),
        content: selectedCountries,
      },
      {
        type: "category",
        title: t("labels.categories"),
        content: selectedCategories,
      },
    ]);
  }, [selectedCountries, selectedCategories, t]);

  return (
    <>
      <NavBar active="gastronomia" />

      {loadedProducers ? (
        <main className="bg-gray">
          <div
            className="relative flex items-center justify-center bg-local bg-center bg-cover h-72 sm:h-80 md:h-heading"
            style={{
              backgroundImage: `url(${heroImage})`,
            }}
          >
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-overlay-3">
              <h1 className="text-4xl text-center text-white sm:text-5xl md:text-6xl lg:text-7xl font-reg sm:font-reg-light">
                {t("labels.makers")}
              </h1>
            </div>
          </div>

          <section className="flex flex-col w-10/12 py-10 mx-auto md:flex-row md:pt-28 md:max-w-reg-grid">
            <div className="w-full pb-8 mr-0 md:pb-0 md:w-3/12 md:mr-7">
              <Filters
                items={filters}
                handleFiltersChange={onFilterChange}
                handleFiltersUpdate={onFilterUpdate}
                handleFiltersClear={onFilterClear}
                handleApplyFilters={onApplyFilters}
              />
            </div>

            <div className="w-full md:w-9/12">
              {producers.length > 0 ? (
                <div className="grid grid-cols-1 gap-4 pb-10 sm:grid-cols-2 lg:grid-cols-3 shadow-filter">
                  {producers.map((produtor) => (
                    <CardProdutor key={produtor.id} produtor={produtor} />
                  ))}
                </div>
              ) : (
                <p className="pt-4 pb-8 text-xl text-center md:pb-0 md:text-2xl font-reg-bold text-blue">
                  {t("labels.noProducers")}
                </p>
              )}

              {totalPages > 1 && totalProducers > PER_PAGE && (
                <Pagination
                  currentPage={page}
                  totalPages={totalPages}
                  handlePageChange={setPage}
                />
              )}
            </div>
          </section>
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default Produtores;
