import React from "react";
import { Switch, Route } from "react-router-dom";

import Eventos from "../pages/Eventos";
import EventoDetalhe from "../pages/EventoDetalhe";
import NotFound from "../pages/404";

const RouteEventos = () => {
  return (
    <Switch>
      <Route path="/pt/acontece/eventos" exact component={Eventos} />
      <Route path="/pt/acontece/eventos/inexistente" exact component={NotFound} />
      <Route path="/pt/acontece/eventos/:id" component={EventoDetalhe} />
      
      <Route path="/en/happening/events" exact component={Eventos} />
      <Route path="/en/happening/events/not-found" exact component={NotFound} />
      <Route path="/en/happening/events/:id" component={EventoDetalhe} />

    </Switch>
  );
};

export default RouteEventos;
