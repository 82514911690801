import React from "react";
import { Switch, Route } from "react-router-dom";

import Restaurantes from "../pages/Restaurantes";
import RestauranteDetalhe from "../pages/RestauranteDetalhe";
import NotFound from "../pages/404";

const RouteRestaurantes = () => {
  return (
    <Switch>
      <Route
        path="/pt/gastronomia/restaurantes"
        exact
        component={Restaurantes}
      />
      <Route
        path="/pt/gastronomia/restaurantes/404"
        exact
        component={NotFound}
      />
      <Route
        path="/pt/gastronomia/restaurantes/:slug"
        component={RestauranteDetalhe}
      />

      <Route path="/en/gastronomy/restaurants" exact component={Restaurantes} />
      <Route path="/en/gastronomy/restaurants/404" exact component={NotFound} />
      <Route
        path="/en/gastronomy/restaurants/:slug"
        component={RestauranteDetalhe}
      />
    </Switch>
  );
};

export default RouteRestaurantes;
