import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardRestaurante = ({ restaurant }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  return (
    <Link
      to={`/${language}/${t("slugs.restaurants")}/${restaurant.slug}`}
      className="w-64 mx-auto overflow-hidden bg-white shadow-card hover:shadow-md gastronomy-card sm:w-80 md:w-72 lg:w-full"
      key={restaurant.id}
    >
      <div className="relative pb-4/5">
        {restaurant.media?.hasOwnProperty("3") && (
          <img
            className="absolute object-cover w-full h-full transition-all duration-500 ease-in-out"
            src={restaurant.media["3"].path}
            alt={restaurant.name}
          />
        )}
      </div>
      <div className="px-4 pt-4 pb-5">
        <h4 className="pb-3 text-base leading-4 text-blue font-reg min-h-12">
          {restaurant.name}
        </h4>
        <div className="flex flex-row items-center text-gray-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="w-4 h-4 mr-2"
          >
            <defs />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M6.00000238 4.0000024c0-1.1015614-.8984338-2-2-2-1.10156142 0-2 .8984386-2 2 0 1.1015662.89843858 2 2 2 1.1015662 0 2-.8984338 2-2zM8 4c0 .4765678-.054678.9687559-.2578033 1.3984439l-2.84375336 6.0468822c-.1640627.3437505-.52343812.5546882-.89844334.5546882-.37499568 0-.73437111-.2109377-.89062129-.5546882L.25781281 5.3984439C.05468757 4.9687559 0 4.4765678 0 4c0-2.2109354 1.78906463-4 4-4 2.2109449 0 4 1.7890646 4 4z"
            />
          </svg>
          <span className="text-sm font-reg-light">{restaurant.country}</span>
        </div>
        <div className="flex flex-row items-center text-gray-dark">
          <svg fill="currentColor" viewBox="0 0 20 20" className="w-4 h-4 mr-2">
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
          </svg>
          <span className="text-sm font-reg-light">{restaurant.contact}</span>
        </div>
      </div>
    </Link>
  );
};

export default CardRestaurante;
