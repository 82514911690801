import React from "react";
import { Switch, Route } from "react-router-dom";

import Produtos from "../pages/Produtos";
import ProdutoDetalhe from "../pages/ProdutoDetalhe";
import NotFound from "../pages/404";

const RouteProdutos = () => {
  return (
    <Switch>
      <Route path="/pt/gastronomia/produtos" exact component={Produtos} />
      <Route path="/pt/gastronomia/produtos/404" exact component={NotFound} />
      <Route path="/pt/gastronomia/produtos/:slug" component={ProdutoDetalhe} />

      <Route path="/en/gastronomy/products" exact component={Produtos} />
      <Route path="/en/gastronomy/products/404" exact component={NotFound} />
      <Route path="/en/gastronomy/products/:slug" component={ProdutoDetalhe} />
    </Switch>
  );
};

export default RouteProdutos;
