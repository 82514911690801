import React from "react";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const NotFound = () => {
  return (
    <>
      <NavBar />

      <main className="flex items-center justify-center h-screen bg-gray">
        <h1 className="text-center font-reg-bold text-7xl text-blue">
          404
        </h1>
      </main>

      <Footer />
    </>
  );
};

export default NotFound;
