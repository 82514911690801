import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardProduto = ({ product, slider = false }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  let linkClass =
    "overflow-hidden bg-white shadow-card hover:shadow-md gastronomy-card lg:w-full ";
  linkClass += slider
    ? "block sm:w-72 my-2 lg:my-0 mx-4 sm:mx-auto h-72 sm:h-auto"
    : "w-64 mx-auto sm:w-80 md:w-72";

  return (
    <Link
      to={`/${language}/${t("slugs.products")}/${product.slug}`}
      className={linkClass}
    >
      <div className="relative bg-white pb-4/5">
        {product.media?.hasOwnProperty("4") && (
          <img
            className="absolute object-cover w-full h-full transition-all duration-500 ease-in-out"
            src={product.media["4"].path}
            alt={product.name}
          />
        )}
      </div>
      <div className="px-4 pt-4 pb-5 bg-white">
        <h4 className="pb-3 text-base leading-4 truncate text-blue font-reg">
          {product.name}
        </h4>
        <p className="text-sm font-reg-light text-gray-dark">
          {product.category?.name}
        </p>
        <div
          className="text-sm truncate font-reg-light text-gray-dark institutional-content"
          dangerouslySetInnerHTML={{
            __html: product.description?.replace(/<[^>]*>?/gm, "").trim(),
          }}
        />
      </div>
    </Link>
  );
};

export default CardProduto;
