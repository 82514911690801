import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AliceCarousel from "react-alice-carousel";

import "react-alice-carousel/lib/alice-carousel.css";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import gastronomyRecipes from "../assets/images/gastronomy-recipes.jpg";
import gastronomyProducts from "../assets/images/gastronomy-products.jpg";
import gastronomyRestaurants from "../assets/images/gastronomy-restaurants.jpg";
import gastronomyTours from "../assets/images/gastronomy-tours.png";
import gastronomyMakers from "../assets/images/gastronomy-makers.png";

const Gastronomia = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { data: content, isLoaded: loaded } = useApiData(
    `${Settings.server}/api/v1/institutional?page_url=gastronomy&lang=${language}`
  );

  const carousel = useRef(null);

  return (
    <>
      <NavBar active="gastronomia" />

      <main className="bg-gray">
        <h1 className="pt-10 pb-4 text-5xl text-center sm:text-6xl text-blue md:pt-26 md:text-7xl font-reg sm:font-reg-light">
          {t("gastronomy.title")}
        </h1>

        <div
          className="px-8 text-sm leading-7 text-center sm:text-base font-reg-light text-blue pb-14"
          dangerouslySetInnerHTML={{
            __html: loaded
              ? content.find((item) => item.section === "subtitle")?.content
              : "",
          }}
        />

        <div className="flex flex-row items-center justify-center xl:mx-auto xl:w-reg">
          <button
            type="button"
            className="z-10 mb-20 ml-2 -mr-6 md:mr-0 xm:mr-12 text-blue"
            onClick={() => carousel.current.slidePrev()}
          >
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-12 h-12">
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div className="w-10/12 pb-20 mx-auto xl:w-reg-grid">
            <AliceCarousel
              buttonsDisabled={true}
              dotsDisabled={true}
              infinite={false}
              ref={carousel}
              responsive={{
                0: {
                  items: 1,
                },
                1024: {
                  items: 2,
                },
                1310: {
                  items: 3,
                },
              }}
            >
              <Link
                to={`/${language}/${t("slugs.products")}`}
                className="flex justify-center"
              >
                <div
                  className="relative max-w-sm ml-3 mr-3 bg-center bg-cover w-104 h-card-image"
                  style={{
                    backgroundImage: `url(${gastronomyProducts})`,
                  }}
                >
                  <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-6 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                    <h4 className="pb-5 text-2xl leading-8 text-white font-reg-bold header-prefix">
                      {t("gastronomy.subtitles.products")}
                    </h4>
                    <div className="flex flex-row items-center pb-8 text-base text-white uppercase font-reg">
                      <span className="bg-bottom bg-no-repeat underline-reg-small underline-reg-white">
                        {t("labels.explore")}
                      </span>
                      <div className="w-4 h-1 ml-2 text-white min-w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 3"
                        >
                          <defs />
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`/${language}/${t("slugs.restaurants")}`}
                className="flex justify-center"
              >
                <div
                  className="relative max-w-sm ml-3 mr-3 bg-center bg-cover w-104 h-card-image"
                  style={{
                    backgroundImage: `url(${gastronomyRestaurants})`,
                  }}
                >
                  <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-6 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                    <h4 className="pb-5 text-2xl leading-8 text-white font-reg-bold header-prefix">
                      {t("gastronomy.subtitles.restaurants")}
                    </h4>
                    <div className="flex flex-row items-center pb-8 text-base text-white uppercase font-reg">
                      <span className="bg-bottom bg-no-repeat underline-reg-small underline-reg-white">
                        {t("labels.explore")}
                      </span>
                      <div className="w-4 h-1 ml-2 text-white min-w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 3"
                        >
                          <defs />
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`/${language}/${t("slugs.recipes")}`}
                className="flex justify-center"
              >
                <div
                  className="relative max-w-sm ml-3 mr-3 bg-center bg-cover w-104 h-card-image"
                  style={{
                    backgroundImage: `url(${gastronomyRecipes})`,
                  }}
                >
                  <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-6 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                    <h4 className="pb-5 text-2xl leading-8 text-white font-reg-bold header-prefix">
                      {t("gastronomy.subtitles.recipes")}
                    </h4>
                    <div className="flex flex-row items-center pb-8 text-base text-white uppercase font-reg">
                      <span className="bg-bottom bg-no-repeat underline-reg-small underline-reg-white">
                        {t("labels.explore")}
                      </span>
                      <div className="w-4 h-1 ml-2 text-white min-w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 3"
                        >
                          <defs />
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`/${language}/${t("slugs.tours")}`}
                className="flex justify-center"
              >
                <div
                  className="relative max-w-sm ml-3 mr-3 bg-center bg-cover w-104 h-card-image"
                  style={{
                    backgroundImage: `url(${gastronomyTours})`,
                  }}
                >
                  <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-6 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                    <h4 className="pb-5 text-2xl leading-8 text-white font-reg-bold header-prefix">
                      {t("gastronomy.subtitles.tours")}
                    </h4>
                    <div className="flex flex-row items-center pb-8 text-base text-white uppercase font-reg">
                      <span className="bg-bottom bg-no-repeat underline-reg-small underline-reg-white">
                        {t("labels.explore")}
                      </span>
                      <div className="w-4 h-1 ml-2 text-white min-w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 3"
                        >
                          <defs />
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`/${language}/${t("slugs.makers")}`}
                className="flex justify-center"
              >
                <div
                  className="relative max-w-sm ml-3 mr-3 bg-center bg-cover w-104 h-card-image"
                  style={{
                    backgroundImage: `url(${gastronomyMakers})`,
                  }}
                >
                  <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-6 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                    <h4 className="pb-5 text-2xl leading-8 text-white font-reg-bold header-prefix">
                      {t("gastronomy.subtitles.makers")}
                    </h4>
                    <div className="flex flex-row items-center pb-8 text-base text-white uppercase font-reg">
                      <span className="bg-bottom bg-no-repeat underline-reg-small underline-reg-white">
                        {t("labels.explore")}
                      </span>
                      <div className="w-4 h-1 ml-2 text-white min-w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 3"
                        >
                          <defs />
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </AliceCarousel>
          </div>
          <button
            type="button"
            className="z-10 mb-20 mr-2 -ml-6 md:ml-12 text-blue"
            onClick={() => carousel.current.slideNext()}
          >
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-12 h-12">
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Gastronomia;
