import React, { Suspense } from "react";
import { BreakpointProvider } from "react-socks";

import "./i18n";

import Routing from "./Routing";
import Loading from "./components/Loading";

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <BreakpointProvider>
        <Routing />
      </BreakpointProvider>
    </Suspense>
  );
};

export default App;
