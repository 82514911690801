import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const TermosCondicoes = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { data: content, isLoaded: contentLoaded } = useApiData(
    `${Settings.server}/api/v1/institutional?page_url=terms&lang=${language}`
  );

  return (
    <>
      <NavBar />

      <main>
        <div className="w-10/12 pt-10 mx-auto text-left lg:w-reg-grid text-blue lg:px-25 sm:pt-12 lg:pt-30">
          <h1 className="pb-8 text-2xl uppercase md:text-3xl font-reg-bold">
            {t("labels.terms")}
          </h1>

          <div
            className="text-sm opacity-75 md:text-base institutional-content font-reg-light"
            dangerouslySetInnerHTML={{
              __html: contentLoaded
                ? content.find((item) => item.section === "content")?.content
                : "",
            }}
          />

          <div className="text-blue pb-26">
            <h2 className="py-4 text-3xl uppercase font-reg-bold">
              {t("labels.privacy")}
            </h2>
            <p className="text-base leading-7 opacity-75 font-reg-light">
              {t("labels.privacyLink1")}{" "}
              <Link
                to={`/${language}/${t("slugs.privacy")}`}
                className="hover:underline font-reg-bold"
              >
                {t("labels.privacy")}
              </Link>{" "}
              {t("labels.privacyLink2")}
            </p>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default TermosCondicoes;
