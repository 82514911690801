import React from "react";
import { useTranslation } from "react-i18next";
import useApiData from "../hooks/useApiData";
import Settings from "../helpers/settings";

const BulletSpacer = () => <>&nbsp;&bull;&nbsp;</>;

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const language = i18n.language;

  const handleClick = (language) => {
    i18n.changeLanguage(language);
  };
  const {
    data
  } = useApiData(
    `${Settings.server}/api/v1/languages`
  );
  return (
    <p>
      {data.map( (value,index)=> {
        return <React.Fragment key={index}><button
        type="button"
      
        onClick={() => handleClick(value.isocode.split("-")[0])}
        className={language === value.isocode.split("-")[0] ? "underline" : "opacity-50"}
      >
        {value.isocode.split("-")[0].toUpperCase()}
      </button>
      <BulletSpacer /></React.Fragment>
      })}
      
      
     
    </p>
  );
};

export default LanguageSwitcher;
