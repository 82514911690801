import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import CardEvento from "../components/CardEvento";
import Pagination from "../components/Pagination";

import heroImage from "../assets/images/events-hero.png";

const Eventos = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const PER_PAGE = 15;
  const [page, setPage] = useState(1);
  const {
    data: events,
    isLoaded: eventsLoaded,
    pagination: eventTotals,
  } = useApiData(
    `${Settings.server}/api/v1/event?sort=-date_start&lang=${language}&per-page=${PER_PAGE}&page=${page}`
  );

  const [totalPages, setTotalPages] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  useEffect(() => {
    setTotalPages(eventTotals.page);
    setTotalEvents(eventTotals.total);
  }, [eventTotals]);

  return (
    <>
      <NavBar active="acontece" />

      {eventsLoaded ? (
        <main className="pb-20 bg-gray">
          <div
            className="relative bg-local bg-center bg-cover h-72 sm:h-80 md:h-heading"
            style={{
              backgroundImage: `url(${heroImage})`,
            }}
          >
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-overlay-4">
              <h1 className="text-4xl text-center text-white sm:text-5xl md:text-6xl lg:text-7xl font-reg sm:font-reg-light">
                {t("labels.events")}
              </h1>
            </div>
          </div>

          <div className="flex flex-col w-10/12 mx-auto lg:max-w-reg-grid">
            <div className="grid w-full grid-cols-1 gap-4 py-10 mx-auto md:py-20 md:grid-cols-2 xl:grid-cols-3 shadow-filter">
              {events.length > 0 && (
                events.map((event) => (
                  <CardEvento key={event.id} event={event} />
                ))
              )}
            </div>

            {totalPages > 1 && totalEvents > PER_PAGE && (
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                handlePageChange={setPage}
              />
            )}
          </div>
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default Eventos;
