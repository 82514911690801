import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import CardNoticia from "../components/CardNoticia";
import Pagination from "../components/Pagination";

import heroImage from "../assets/images/news-hero.png";
import Loading from "../components/Loading";

const Noticias = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const PER_PAGE = 15;
  const [page, setPage] = useState(1);
  const {
    data: articles,
    isLoaded: articlesLoaded,
    pagination: articleTotals,
  } = useApiData(
    `${Settings.server}/api/v1/article?sort=-publish_date&lang=${language}&per-page=${PER_PAGE}&page=${page}`
  );

  const [totalPages, setTotalPages] = useState(0);
  const [totalArticles, setTotalArticles] = useState(0);
  useEffect(() => {
    setTotalPages(articleTotals.page);
    setTotalArticles(articleTotals.total);
  }, [articleTotals]);

  return (
    <>
      <NavBar active="acontece" />

      {articlesLoaded ? (
        <main className="pb-20 bg-gray">
          <div
            className="relative bg-local bg-center bg-cover h-72 sm:h-80 md:h-heading"
            style={{
              backgroundImage: `url(${heroImage})`,
            }}
          >
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-overlay-4 ">
              <h1 className="text-4xl text-center text-white sm:text-5xl md:text-6xl lg:text-7xl font-reg sm:font-reg-light">
                {t("labels.news")}
              </h1>
            </div>
          </div>

          <div className="flex flex-col w-10/12 mx-auto lg:max-w-reg-grid">
            <div className="grid w-full grid-cols-1 gap-4 py-10 mx-auto md:py-20 md:grid-cols-2 xl:grid-cols-3 shadow-filter">
              {articles.length > 0 &&
                articles.map((article) => (
                  <CardNoticia key={article.id} article={article} />
                ))}
            </div>

            {totalPages > 1 && totalArticles > PER_PAGE && (
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                handlePageChange={setPage}
              />
            )}
          </div>
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default Noticias;
