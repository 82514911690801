import React from "react";
import { useTranslation } from "react-i18next";

import useModal from "../hooks/useModal";

import Checkbox from "./FiltersCheckbox";

const FiltersMobile = ({
  items,
  handleFiltersChange,
  handleFiltersClear,
  handleApplyFilters,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal, isOpen, Modal } = useModal();

  const handleClear = (e) => {
    handleFiltersClear();
    closeModal(e);
  };

  const handleApply = (e) => {
    handleApplyFilters();
    closeModal(e);
  };

  return (
    <>
      <div className="flex flex-row-reverse text-blue font-reg">
        <button
          onClick={(e) => openModal(e)}
          type="button"
          className="px-3 py-2 text-white border rounded shadow-button focus:outline-none border-grayish-blue bg-blue hover:bg-transparent hover:text-blue hover:border-blue"
        >
          {t("labels.filters")}
        </button>
      </div>
      {isOpen && (
        <Modal>
          <div className="fixed inset-x-0 bottom-0 z-30 flex items-center justify-center w-full h-full bg-overlay-6">
            <div className="w-10/12 mx-auto bg-white">
              <div className="flex items-center justify-between h-16 px-8 border-b border-cool-gray-300">
                <h3 className="text-xl leading-6 font-reg text-blue">
                  {t("labels.filters")}
                </h3>
                <span
                  className="mb-1 text-3xl leading-6 cursor-pointer text-cool-gray-700"
                  onClick={(e) => closeModal(e)}
                >
                  &times;
                </span>
              </div>
              <div className="px-8 py-6 overflow-y-scroll h-104">
                {items.map((filter, i) => (
                  <div key={i} className="pb-4">
                    <h6 className="pb-5 text-base text-gray-dark font-reg-bold">
                      {filter.title}
                    </h6>
                    <div className="flex flex-col">
                      {filter.content.map((item) => (
                        <div className="mb-3" key={item.id}>
                          <Checkbox
                            value={item.id}
                            label={item.value}
                            checked={item.checked}
                            type={items.length > 1 ? filter.type : false}
                            onChange={handleFiltersChange}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between px-8 py-4 border-t border-cool-gray-300 font-reg">
                <button
                  type="button"
                  className="w-1/2 px-3 py-2 mr-2 text-white border rounded sm:w-auto shadow-button focus:outline-none border-grayish-blue bg-blue hover:bg-transparent hover:text-blue hover:border-blue"
                  onClick={(e) => handleApply(e)}
                >
                  {t("labels.apply")}
                </button>
                <button
                  type="button"
                  onClick={(e) => handleClear(e)}
                  className="w-1/2 px-3 py-2 ml-2 border rounded sm:w-auto shadow-button focus:outline-none border-blue hover:border-grayish-blue text-blue hover:text-white hover:bg-blue"
                >
                  {t("labels.clear")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FiltersMobile;
