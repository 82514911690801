import React, { useState, useEffect, useRef } from "react";
import functions from "lodash.functions";
import isEqual from "lodash.isequal";
import omit from "lodash.omit";

function Map({ options, onMount, className, onMountProps }) {
  const ref = useRef();
  const [map, setMap] = useState();

  useEffect(() => {
    const onLoad = () =>
      setMap(new window.google.maps.Map(ref.current, { ...options }));

    if (!window.google) {
      const script = document.createElement(`script`);
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC5NZAcQFBuNbM3LRKIfaJeCzQwexZohJQ`;
      document.head.append(script);
      script.addEventListener(`load`, onLoad);
      return () => script.removeEventListener(`load`, onLoad);
    } else {
      onLoad();
    }
  }, [options]);

  if (map && typeof onMount === `function`) onMount(map, onMountProps);

  return <div style={{ height: `350px` }} {...{ ref, className }} />;
}

function shouldNotUpdate(props, nextProps) {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)];
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs));
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every((fn) => props[fn].toString() === nextProps[fn].toString());
  return noPropChange && noFuncChange;
}

export default React.memo(Map, shouldNotUpdate);

Map.defaultProps = {
  options: {
    center: { lat: 40.208389, lng: -8.414205 },
    zoom: 9,
  },
};
