import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const IconGastronomy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 489.773 489.773"
    className="w-4 h-4 mr-2"
  >
    <defs />
    <g fill="currentColor">
      <path d="M155.183 305.646c-2.081.175-4.157.265-6.238.265-5.693 0-11.23-.826-16.652-2.081L6.834 429.293c-6.158 6.149-6.158 16.137 0 22.287l32.47 32.478c6.158 6.15 16.135 6.15 22.276 0l150.785-150.757-27.944-30.15-29.238 2.495zM485.345 104.649c-5.888-5.885-15.417-5.885-21.304 0l-81.303 81.301c-7.693 7.685-20.154 7.685-27.847 0-7.659-7.679-7.659-20.13 0-27.807l80.901-80.884c6.112-6.118 6.112-16.036 0-22.168-6.141-6.11-16.055-6.11-22.167 0l-80.868 80.876c-7.693 7.693-20.14 7.693-27.833 0-7.677-7.676-7.677-20.136 0-27.806l81.286-81.293c5.904-5.894 5.904-15.441 0-21.343-5.888-5.895-15.434-5.895-21.338 0l-91.458 91.463c-21.989 22.003-28.935 52.888-21.816 80.991l61.31 61.314c28.101 7.093 59.001.144 80.965-21.841l91.471-91.458c5.905-5.894 5.905-15.451.001-21.345z" />
      <path d="M41.093 13.791c-3.134-3.135-7.372-4.854-11.724-4.854-.926 0-1.857.079-2.766.231-5.295.896-9.838 4.295-12.172 9.133-26.79 55.373-15.594 121.631 27.894 165.121l77.801 77.791c7.676 7.685 18.055 11.939 28.819 11.939 1.151 0 2.305-.048 3.456-.143l45.171-3.855 196.971 212.489c3.058 3.303 7.342 5.221 11.855 5.31h.288c4.412 0 8.636-1.743 11.771-4.855l33.734-33.741c3.117-3.11 4.859-7.331 4.859-11.73 0-4.398-1.742-8.622-4.846-11.732L41.093 13.791z" />
    </g>
  </svg>
);

const IconChef = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33.971 33.971"
    className="w-4 h-4 mr-2"
  >
    <defs />
    <path
      fill="currentColor"
      d="M25.651 8.326c.002-.062.012-.123.012-.186C25.662 3.646 22.019 0 17.52 0c-4.495 0-8.141 3.646-8.141 8.141v.035c-4.191.332-7.488 3.832-7.488 8.109 0 4.291 3.317 7.795 7.521 8.111v5.746h16.216v-5.895c3.686-.773 6.453-4.041 6.453-7.963 0-3.907-2.756-7.171-6.43-7.958zM9.478 30.742h16.089v3.229H9.478z"
    />
  </svg>
);

const IconCalendar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 14"
    className="w-4 h-4 mr-2"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.00000119 13.0000155h2.25000268v-2.2500027H1.00000119v2.2500027zm2.75000328 0h2.5v-2.2500027h-2.5v2.2500027zm-2.75000328-2.7500063h2.25000268V7.75000924H1.00000119v2.49999996zm2.75000328 0h2.5V7.75000924h-2.5v2.49999996zM1.00000119 7.25000864h2.25000268V5.00000596H1.00000119v2.25000268zm5.75000686 5.75000686h2.5v-2.2500027h-2.5v2.2500027zM3.75000447 7.25000864h2.5V5.00000596h-2.5v2.25000268zm6.00000715 5.75000686h2.25000268v-2.2500027H9.75001162v2.2500027zm-3.00000357-2.7500063h2.5V7.75000924h-2.5v2.49999996zM4.00000358 3.50000119v-2.2499997c0-.13281266-.11718645-.2500003-.24999911-.2500003h-.5000006c-.13281265 0-.25000029.11718764-.25000029.2500003v2.2499997c0 .13281564.11718764.25000328.25000029.25000328h.5000006c.13281266 0 .24999911-.11718764.24999911-.25000328zm5.75000804 6.75000801h2.25000268V7.75000924H9.75001162v2.49999996zM6.75000805 7.25000864h2.5V5.00000596h-2.5v2.25000268zm3.00000357 0h2.25000268V5.00000596H9.75001162v2.25000268zm.24999908-3.75000745v-2.2499997c0-.13281266-.11718642-.2500003-.24999908-.2500003h-.50000059c-.13281266 0-.2500003.11718764-.2500003.2500003v2.2499997c0 .13281564.11718764.25000328.2500003.25000328h.50000059c.13281266 0 .24999908-.11718764.24999908-.25000328zM13.0000155 3v10.0000155c0 .5468756-.4531255 1.0000012-1.0000012 1.0000012H1c-.54687446 0-1-.4531256-1-1.0000012V3c0-.54687208.45312554-1 1-1h1v-.74999851C2 .56250067 2.56250305 0 3.25000387 0h.5000006C4.43750529 0 5 .56250067 5 1.25000149V2h3v-.74999851C8 .56250067 8.56251021 0 9.25001103 0h.50000059c.68750078 0 1.25000148.56250067 1.25000148 1.25000149V2h1.0000012c.5468757 0 1.0000012.45312792 1.0000012 1z"
    />
  </svg>
);

const IconMarker = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 12"
    className="w-4 h-4 mr-2"
  >
    <defs />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.00000238 4.0000024c0-1.1015614-.8984338-2-2-2-1.10156142 0-2 .8984386-2 2 0 1.1015662.89843858 2 2 2 1.1015662 0 2-.8984338 2-2zM8 4c0 .4765678-.054678.9687559-.2578033 1.3984439l-2.84375336 6.0468822c-.1640627.3437505-.52343812.5546882-.89844334.5546882-.37499568 0-.73437111-.2109377-.89062129-.5546882L.25781281 5.3984439C.05468757 4.9687559 0 4.4765678 0 4c0-2.2109354 1.78906463-4 4-4 2.2109449 0 4 1.7890646 4 4z"
    />
  </svg>
);

const IconPhone = () => (
  <svg fill="currentColor" viewBox="0 0 20 20" className="w-4 h-4 mr-2">
    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
  </svg>
);

const CardIcon = ({ icon, text }) => {
  return (
    <div className="flex flex-row items-center text-gray-dark">
      {icon}
      <span className="text-sm font-reg-light">{text}</span>
    </div>
  );
};

const CardTitle = ({ item }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  let title = "",
    link = "";

  switch (item.type) {
    case "restaurant":
      title = item.name;
      link = `/${language}/${t("slugs.restaurants")}/${item.slug}`;
      break;
    case "recipe":
      title = item.name;
      link = `/${language}/${t("slugs.recipes")}/${item.slug}`;
      break;
    case "article":
      title = item.title;
      link = `/${language}/${t("slugs.news")}/${item.id}`;
      break;
    case "event":
      title = item.title;
      link = "";
      break;
    default:
      break;
  }

  return (
    <>
      {link.length > 0 ? (
        <Link to={link}>
          <h4 className="pb-2 text-base leading-4 text-blue font-reg-bold hover:underline">
            {title}
          </h4>
        </Link>
      ) : (
        <h4 className="pb-2 text-base leading-4 text-blue font-reg-bold">
          {title}
        </h4>
      )}
    </>
  );
};

const CardImage = ({ item }) => {
  let imageSrc = "";

  switch (item.type) {
    case "restaurant":
      imageSrc = item.media?.hasOwnProperty("3") ? item.media["3"].path : "";
      break;
    case "recipe":
      imageSrc = item.media?.hasOwnProperty("9") ? item.media["9"].path : "";
      break;
    case "article":
      imageSrc = item.media.length > 0 ? item.media[0].path : "";
      break;
    case "event":
      imageSrc = item.media?.hasOwnProperty("8") ? item.media["8"].path : "";
      break;
    default:
      break;
  }

  return (
    <div className="relative pb-5/12">
      <img
        className="absolute object-cover w-full h-full"
        src={imageSrc}
        alt={item.hasOwnProperty('title') ? item.title : item.name}
      />
    </div>
  );
};

const CardBody = ({ item }) => {
  let body = "";

  switch (item.type) {
    case "restaurant":
      body = (
        <>
          <CardIcon icon={<IconMarker />} text={item.country} />
          <CardIcon icon={<IconPhone />} text={item.contact} />
        </>
      );
      break;
    case "recipe":
      body = (
        <>
          <CardIcon icon={<IconGastronomy />} text={item.category?.name} />
          <CardIcon icon={<IconChef />} text={item.source} />
        </>
      );
      break;
    case "article":
      body = (
        <div className="font-sans text-sm leading-4 text-blue opacity-60">
          <p>
            {item.excerpt?.length >= 250
              ? item.excerpt
                  .replace(/<[^>]*>?/gm, "")
                  .split(" ")
                  .splice(0, 20)
                  .join(" ") + " ..."
              : item.excerpt.replace(/<[^>]*>?/gm, "").trim()}
          </p>
        </div>
      );
      break;

    case "event":
      body = (
        <>
          <CardIcon icon={<IconCalendar />} text={item.date_string} />
          <CardIcon icon={<IconMarker />} text={item.location} />
        </>
      );
      break;
    default:
      break;
  }
  return body;
};

const CardPesquisa = ({ item }) => {
  return (
    <div className="w-full mx-auto overflow-hidden bg-white md:pb-10 sm:w-72 md:w-full">
      <CardImage item={item} />
      <div className="pt-3">
        <CardTitle item={item} />
        <CardBody item={item} />
      </div>
    </div>
  );
};

export default CardPesquisa;
