import React, { useRef, useCallback } from "react";
import usePortal from "react-useportal";

const useModal = () => {
  const modal = useRef();

  let { isOpen, openPortal, togglePortal, closePortal, Portal } = usePortal();

  const Modal = useCallback(
    (props) => (
      <Portal>
        <div ref={modal} {...props} />
      </Portal>
    ),
    [modal]
  );

  return {
    Modal,
    openModal: openPortal,
    toggleModal: togglePortal,
    closeModal: closePortal,
    isOpen,
  };
};

export default useModal;
