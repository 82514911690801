import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardNoticia = ({ article, border = true, slider = false }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  let linkClass = "bg-white overflow-hidden news-card ";
  linkClass += slider
    ? "block h-72 sm:mx-auto sm:w-96 xl:w-full my-2 mx-2 sm:my-0 sm:h-auto"
    : "mx-auto w-80 sm:w-96 lg:w-full";
  linkClass += border ? " shadow-card hover:shadow-md" : "";

  const bodyClass = "pt-6 bg-white" + (border ? " px-4" : "");

  return (
    <Link
      to={`/${language}/${t("slugs.news")}/${article.id}`}
      className={linkClass}
    >
      <div className="relative bg-white pb-5/12">
        <img
          className="absolute object-cover w-full h-full transition-all duration-500 ease-in-out"
          src={article.media.find((m) => m.type === 13)?.path}
          alt={article.title}
        />
      </div>
      <div className={bodyClass}>
        <h4 className="pb-3 overflow-hidden text-base leading-5 uppercase transition-all duration-300 ease-in-out sm:text-lg text-blue font-reg min-h-16 max-h-24 sm:max-h-0">
          {article.title}
        </h4>
        <div
          className="invisible hidden pb-2 overflow-hidden text-base leading-5 opacity-75 font-reg-light text-blue h-22 sm:flex sm:visible"
          dangerouslySetInnerHTML={{
            __html:
              article.excerpt?.length > 150
                ? article.excerpt
                    ?.replace(/<[^>]*>?/gm, "")
                    .trim()
                    .substring(0, 150)
                : article.excerpt?.replace(/<[^>]*>?/gm, "").trim(),
          }}
        />
        <div className="text-xs sm:text-sm font-reg text-cool-gray-300">
          {article.publish_date}
        </div>
        <div className="flex justify-end">
          <div className="flex flex-row items-center h-6 mt-2 mb-4 text-sm leading-3 uppercase sm:mt-4 sm:mb-6 sm:text-base font-reg text-blue">
            <span className="bg-bottom bg-no-repeat underline-reg underline-reg-blue">
              {t("labels.read")}
            </span>
            <div className="w-4 h-1 ml-2 text-blue min-w-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 3"
                className="fill-current"
              >
                <defs />
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardNoticia;
