import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breakpoint } from "react-socks";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import blueLogoPt from "../assets/logo/logo-rc-reg2021.svg";
import whiteLogoPt from "../assets/logo/logo-rc-reg2021-white.svg";
import blueLogoEn from "../assets/logo/logo-rc-reg2021-en.svg";
import whiteLogoEn from "../assets/logo/logo-rc-reg2021-white-en.svg";

import gastronomyRecipes from "../assets/images/gastronomy-recipes.jpg";
import gastronomyProducts from "../assets/images/gastronomy-products.jpg";
import gastronomyRestaurants from "../assets/images/gastronomy-restaurants.jpg";
import gastronomyTours from "../assets/images/gastronomy-tours.png";
import gastronomyMakers from "../assets/images/gastronomy-makers.png";

import happeningNews from "../assets/images/news-hero.png";
import happeningEvents from "../assets/images/events-hero.png";
import happeningMarkets from "../assets/images/rota-mercados.jpg";

const GastronomiaDropdown = ({ transparent, language }) => {
  const { t } = useTranslation();

  let sectionClass = "absolute left-0 w-full nav-gastronomy-section ";
  sectionClass += transparent ? "pt-11" : "pt-6";

  const wrapperClass =
    "w-full py-6 " + (transparent ? "bg-dropdown" : "bg-white");

  return (
    <section className={sectionClass}>
      <div className={wrapperClass}>
        <div className="grid grid-cols-6 mx-auto max-w-reg">
          <div className="normal-case text-blue">
            <h4 className="text-2xl leading-7 font-reg">
              {t("labels.dropdownTitle")}
            </h4>
            {/*
            <p className="text-xs leading-5 font-reg-light">
              {t("labels.dropdownText")}
            </p>
            */}
          </div>

          <Link to={`/${language}/${t("slugs.products")}`}>
            <div
              className="relative ml-3 mr-3 transition-all duration-200 ease-in-out bg-center bg-cover h-52 dropdown-card"
              style={{
                backgroundImage: `url(${gastronomyProducts})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-3 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <p className="pb-5 text-base leading-5 text-white normal-case font-reg">
                  {t("labels.products")}
                </p>
              </div>
            </div>
          </Link>

          <Link to={`/${language}/${t("slugs.restaurants")}`}>
            <div
              className="relative ml-3 mr-3 transition-all duration-200 ease-in-out bg-center bg-cover h-52 dropdown-card"
              style={{
                backgroundImage: `url(${gastronomyRestaurants})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-3 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <p className="pb-5 text-base leading-5 text-white normal-case font-reg">
                  {t("labels.restaurants")}
                </p>
              </div>
            </div>
          </Link>

          <Link to={`/${language}/${t("slugs.recipes")}`}>
            <div
              className="relative ml-3 mr-3 transition-all duration-200 ease-in-out bg-center bg-cover h-52 dropdown-card"
              style={{
                backgroundImage: `url(${gastronomyRecipes})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-3 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <p className="pb-5 text-base leading-5 text-white normal-case font-reg">
                  {t("labels.recipesTitle")}
                </p>
              </div>
            </div>
          </Link>

          <Link to={`/${language}/${t("slugs.tours")}`}>
            <div
              className="relative ml-3 mr-3 transition-all duration-200 ease-in-out bg-center bg-cover h-52 dropdown-card"
              style={{
                backgroundImage: `url(${gastronomyTours})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-3 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <p className="pb-5 text-base leading-5 text-white normal-case font-reg">
                  {t("labels.tours")}
                </p>
              </div>
            </div>
          </Link>

          <Link to={`/${language}/${t("slugs.makers")}`}>
            <div
              className="relative ml-3 mr-3 transition-all duration-200 ease-in-out bg-center bg-cover h-52 dropdown-card"
              style={{
                backgroundImage: `url(${gastronomyMakers})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-3 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <p className="pb-5 text-base leading-5 text-white normal-case font-reg">
                  {t("labels.makers")}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

const AconteceDropdown = ({ transparent, language }) => {
  const { t } = useTranslation();

  let sectionClass = "absolute left-0 w-full nav-happening-section ";
  sectionClass += transparent ? "pt-11" : "pt-6";

  const wrapperClass =
    "w-full py-6 " + (transparent ? "bg-dropdown" : "bg-white");

  return (
    <section className={sectionClass}>
      <div className={wrapperClass}>
        <div className="grid max-w-4xl grid-cols-4 mx-auto">
          <div className="normal-case text-blue">
            <h4 className="text-2xl leading-7 font-reg">
              {t("labels.promoTitle")}
            </h4>
            {/*
            <p className="text-xs leading-5 font-reg-light">
              {t("labels.promoText")}
            </p>
            */}
          </div>

          <Link to={`/${language}/${t("slugs.news")}`}>
            <div
              className="relative ml-3 mr-3 transition-all duration-200 ease-in-out bg-center bg-cover h-52 dropdown-card"
              style={{
                backgroundImage: `url(${happeningNews})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-3 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <p className="pb-5 text-base leading-5 text-white normal-case font-reg">
                  {t("labels.news")}
                </p>
              </div>
            </div>
          </Link>

          <Link to={`/${language}/${t("slugs.events")}`}>
            <div
              className="relative ml-3 mr-3 transition-all duration-200 ease-in-out bg-center bg-cover h-52 dropdown-card"
              style={{
                backgroundImage: `url(${happeningEvents})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-3 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <p className="pb-5 text-base leading-5 text-white normal-case font-reg">
                  {t("labels.events")}
                </p>
              </div>
            </div>
          </Link>

          <Link to={`/${language}/${t("slugs.rotamercados")}`}>
            <div
              className="relative ml-3 mr-3 transition-all duration-200 ease-in-out bg-center bg-cover h-52 dropdown-card"
              style={{
                backgroundImage: `url(${happeningMarkets})`,
              }}
            >
              <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-3 transition-all duration-200 ease-in bg-overlay-5 hover:bg-overlay-3">
                <p className="pb-5 text-base leading-5 text-white normal-case font-reg">
                  {t("labels.rotamercados")}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

const NavBarDesktop = ({
  active,
  content,
  contentLoaded,
  iconColorClass,
  transparent,
  language,
  logo,
}) => {
  const { t } = useTranslation();

  const navClass =
    "flex items-center justify-between" +
    (!transparent ? " mx-auto max-w-reg" : "");

  const wrapperClass =
    "flex items-center text-base uppercase font-reg-bold " +
    (transparent ? "text-white" : "text-blue");

  const homeLinkClass =
    "hover:underline px-5" + (active === "home" ? " underline" : "");
  const projectLinkClass =
    "hover:underline px-5" + (active === "projeto" ? " underline" : "");
  const contactsLinkClass =
    "hover:underline px-5" + (active === "contactos" ? " underline" : "");

  const gastronomySpanClass =
    "pr-2" + (active === "gastronomia" ? " underline" : "");
  const happeningSpanClass =
    "pr-2" + (active === "acontece" ? " underline" : "");

  const socialNetworkIconClass =
    "w-5 mr-3 " + (iconColorClass === "text-blue" ? "text-blue" : "text-white");
  const searchIconClass =
    "w-10 mr-3 " +
    (iconColorClass === "text-blue" ? "text-blue" : "text-white");

  return (
    <header>
      <nav className={navClass}>
        <Link to="/" className="text-white">
          <img
            className={transparent ? "h-16" : "h-12"}
            src={logo}
            alt={t("alt.navbarLogo")}
          />
        </Link>
        <div className={wrapperClass}>
          <Link to={`/${language}`} className={homeLinkClass}>
            {t("labels.home")}
          </Link>
          <Link
            to={`/${language}/${t("slugs.project")}`}
            className={projectLinkClass}
          >
            {t("labels.project")}
          </Link>
          <div className="nav-gastronomy-menu">
            <Link
              to={`/${language}/${t("slugs.gastronomy")}`}
              className="flex items-center px-5 hover:underline"
            >
              <span className={gastronomySpanClass}>
                {t("labels.gastronomy")}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 12"
                className="w-3"
              >
                <path fill="currentColor" d="M0 3l6 6 6-6H0z" />
              </svg>
            </Link>
            <GastronomiaDropdown
              transparent={transparent}
              language={language}
            />
          </div>
          <div className="nav-happening-menu">
            <Link
              to={`/${language}/${t("slugs.happening")}`}
              className="flex items-center px-5 hover:underline"
            >
              <span className={happeningSpanClass}>
                {t("labels.happening")}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 12"
                className="w-3"
              >
                <path fill="currentColor" d="M0 3l6 6 6-6H0z" />
              </svg>
            </Link>
            <AconteceDropdown transparent={transparent} language={language} />
          </div>
          <Link
            to={`/${language}/${t("slugs.contacts")}`}
            className={contactsLinkClass}
          >
            {t("labels.contacts")}
          </Link>
        </div>
        <div className="flex items-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              contentLoaded
                ? content
                    .find((item) => item.section === "facebook")
                    .content.replace(/<[^>]*>?/gm, "")
                    .trim()
                : "https://facebook.com"
            }
            className={socialNetworkIconClass}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                fill="currentColor"
                d="M17.1 0H2.9C1.3 0 0 1.3 0 2.9V17c0 1.7 1.3 3 2.9 3h5.9v-7.1H6.5V9.4h2.3V7c0-1.9 1.6-3.5 3.5-3.5h3.6V7h-3.6v2.4h3.6l-.6 3.5h-3V20H17c1.6 0 2.9-1.3 2.9-2.9V2.9C20 1.3 18.7 0 17.1 0z"
              />
            </svg>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              contentLoaded
                ? content
                    .find((item) => item.section === "instagram")
                    .content.replace(/<[^>]*>?/gm, "")
                    .trim()
                : "https://instagram.com"
            }
            className={socialNetworkIconClass}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                fill="currentColor"
                d="M5.8 0h8.4C17.4 0 20 2.6 20 5.8v8.4c0 3.2-2.6 5.8-5.8 5.8H5.8C2.6 20 0 17.4 0 14.2V5.8C0 2.6 2.6 0 5.8 0m-.2 2C3.6 2 2 3.6 2 5.6v8.8c0 2 1.6 3.6 3.6 3.6h8.8c2 0 3.6-1.6 3.6-3.6V5.6c0-2-1.6-3.6-3.6-3.6H5.6m9.7 1.5c.7 0 1.3.6 1.3 1.3S15.9 6 15.3 6 14 5.4 14 4.8s.6-1.3 1.3-1.3M10 5c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5m0 2c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"
              />
            </svg>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              contentLoaded
                ? content
                    .find((item) => item.section === "youtube")
                    .content.replace(/<[^>]*>?/gm, "")
                    .trim()
                : "https://youtube.com"
            }
            className={socialNetworkIconClass}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                fill="currentColor"
                d="M13.2 10L8 7v6l5.2-3zm6.4-4.8c.1.5.2 1.1.3 1.9.1.8.1 1.5.1 2.1v.8c0 2.2-.2 3.8-.4 4.8-.3.9-.8 1.5-1.7 1.7-.5.1-1.3.2-2.6.3-1.3.1-2.5.1-3.6.1L10 17c-4.2 0-6.8-.2-7.8-.4-.9-.3-1.5-.8-1.7-1.7-.1-.5-.2-1.1-.3-1.9-.1-.8-.1-1.5-.1-2.1L0 10c0-2.2.2-3.8.4-4.8.3-.9.8-1.5 1.7-1.7.5-.1 1.3-.2 2.7-.3 1.3-.1 2.5-.1 3.6-.1L10 3c4.2 0 6.8.2 7.8.4.9.3 1.5.9 1.8 1.8z"
              />
            </svg>
          </a>
          <div className="ml-3">
            <Link to={`/${language}/${t("slugs.search")}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 12"
                className={searchIconClass}
              >
                <defs />
                <path
                  fill="currentColor"
                  d="M6 0l6 6-6 6-6-6 6-6zm0 .5L.5 6 6 11.5 11.5 6 6 .5zM5.5 4C6.3 4 7 4.7 7 5.5c0 .4-.1.7-.4 1l.1.1h.2L8 7.7l-.3.3-1.2-1.1V6.6c-.3.2-.6.4-1 .4C4.7 7 4 6.3 4 5.5S4.7 4 5.5 4m0 .5c-.6 0-1 .5-1 1s.5 1 1 1 1-.5 1-1-.4-1-1-1z"
                />
              </svg>
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

const NavBarMobile = ({
  active,
  content,
  contentLoaded,
  transparent,
  language,
  logo,
}) => {
  const { t } = useTranslation();
  const [menuVisibility, setMenuVisibility] = useState(false);

  const searchIconClass =
    "w-10 mr-3 " + (transparent ? "text-white" : "text-blue");

  const wrapperClass =
    "transition-all duration-150 ease-in-out absolute flex flex-col items-center w-full p-8 text-2xl uppercase shadow-header font-reg-bold text-blue " +
    (transparent ? "bg-dropdown-dark" : "bg-white");

  const homeLinkClass =
    "hover:underline px-5 mb-4" + (active === "home" ? " underline" : "");
  const projectLinkClass =
    "hover:underline px-5 mb-4" + (active === "projeto" ? " underline" : "");
  const gastronomyLinkClass =
    "hover:underline px-5 mb-2" +
    (active === "gastronomia" ? " underline" : "");
  const happeningLinkClass =
    "hover:underline px-5 mb-2" + (active === "acontece" ? " underline" : "");
  const contactsLinkClass =
    "hover:underline px-5" + (active === "acontece" ? " underline" : "");

  return (
    <>
      <header className="p-4 text-blue">
        <nav className="flex flex-row items-center justify-between">
          <Link to={`/${language}`}>
            <img
              className={transparent ? "h-10 sm:h-14" : "h-10"}
              src={logo}
              alt={t("alt.navbarLogo")}
            />
          </Link>

          <div className="flex flex-row">
            <Link to={`/${language}/${t("slugs.search")}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 12"
                className={searchIconClass}
              >
                <defs />
                <path
                  fill="currentColor"
                  d="M6 0l6 6-6 6-6-6 6-6zm0 .5L.5 6 6 11.5 11.5 6 6 .5zM5.5 4C6.3 4 7 4.7 7 5.5c0 .4-.1.7-.4 1l.1.1h.2L8 7.7l-.3.3-1.2-1.1V6.6c-.3.2-.6.4-1 .4C4.7 7 4 6.3 4 5.5S4.7 4 5.5 4m0 .5c-.6 0-1 .5-1 1s.5 1 1 1 1-.5 1-1-.4-1-1-1z"
                />
              </svg>
            </Link>

            <button
              onClick={() => setMenuVisibility((s) => !s)}
              className={transparent ? "text-white" : "text-blue"}
            >
              {!menuVisibility ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-10 h-10"
                >
                  <defs />
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M12 16v1H4v-1h8zm8-4v1H4v-1h16zm0-4v1h-8V8h8z"
                  />
                </svg>
              ) : (
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  className="w-10 h-8"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
            </button>
          </div>
        </nav>
      </header>
      {menuVisibility && (
        <div className={wrapperClass}>
          <Link to={`/${language}`} className={homeLinkClass}>
            {t("labels.home")}
          </Link>
          <Link
            to={`/${language}/${t("slugs.project")}`}
            className={projectLinkClass}
          >
            {t("labels.project")}
          </Link>
          <Link
            to={`/${language}/${t("slugs.gastronomy")}`}
            className={gastronomyLinkClass}
          >
            {t("labels.gastronomy")}
          </Link>
          <div className="flex flex-col items-center mb-4">
            <Link
              to={`/${language}/${t("slugs.products")}`}
              className="px-5 mb-2 text-base hover:underline"
            >
              {t("labels.products")}
            </Link>
            <Link
              to={`/${language}/${t("slugs.restaurants")}`}
              className="px-5 mb-2 text-base hover:underline"
            >
              {t("labels.restaurants")}
            </Link>
            <Link
              to={`/${language}/${t("slugs.recipes")}`}
              className="px-5 mb-2 text-base hover:underline"
            >
              {t("labels.recipesTitle")}
            </Link>
            <Link
              to={`/${language}/${t("slugs.tours")}`}
              className="px-5 mb-2 text-base hover:underline"
            >
              {t("labels.tours")}
            </Link>
            <Link
              to={`/${language}/${t("slugs.makers")}`}
              className="px-5 text-base hover:underline"
            >
              {t("labels.makers")}
            </Link>
          </div>
          <Link
            to={`/${language}/${t("slugs.happening")}`}
            className={happeningLinkClass}
          >
            {t("labels.happening")}
          </Link>
          <div className="flex flex-col items-center mb-4">
            <Link
              to={`/${language}/${t("slugs.news")}`}
              className="px-5 mb-2 text-base hover:underline"
            >
              {t("labels.news")}
            </Link>
            <Link
              to={`/${language}/${t("slugs.events")}`}
              className="px-5 mb-2 text-base hover:underline"
            >
              {t("labels.events")}
            </Link>
            <Link
              to={`/${language}/${t("slugs.rotamercados")}`}
              className="px-5 text-base hover:underline"
            >
              {t("labels.rotamercados")}
            </Link>
          </div>
          <Link
            to={`/${language}/${t("slugs.contacts")}`}
            className={contactsLinkClass}
          >
            {t("labels.contacts")}
          </Link>
          <div className="flex items-center justify-center w-full my-8 text-blue">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                contentLoaded
                  ? content
                      .find((item) => item.section === "facebook")
                      .content.replace(/<[^>]*>?/gm, "")
                      .trim()
                  : "https://facebook.com"
              }
              className="w-6 mr-4"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  fill="currentColor"
                  d="M17.1 0H2.9C1.3 0 0 1.3 0 2.9V17c0 1.7 1.3 3 2.9 3h5.9v-7.1H6.5V9.4h2.3V7c0-1.9 1.6-3.5 3.5-3.5h3.6V7h-3.6v2.4h3.6l-.6 3.5h-3V20H17c1.6 0 2.9-1.3 2.9-2.9V2.9C20 1.3 18.7 0 17.1 0z"
                />
              </svg>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                contentLoaded
                  ? content
                      .find((item) => item.section === "instagram")
                      .content.replace(/<[^>]*>?/gm, "")
                      .trim()
                  : "https://instagram.com"
              }
              className="w-6 mr-4"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  fill="currentColor"
                  d="M5.8 0h8.4C17.4 0 20 2.6 20 5.8v8.4c0 3.2-2.6 5.8-5.8 5.8H5.8C2.6 20 0 17.4 0 14.2V5.8C0 2.6 2.6 0 5.8 0m-.2 2C3.6 2 2 3.6 2 5.6v8.8c0 2 1.6 3.6 3.6 3.6h8.8c2 0 3.6-1.6 3.6-3.6V5.6c0-2-1.6-3.6-3.6-3.6H5.6m9.7 1.5c.7 0 1.3.6 1.3 1.3S15.9 6 15.3 6 14 5.4 14 4.8s.6-1.3 1.3-1.3M10 5c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5m0 2c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"
                />
              </svg>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                contentLoaded
                  ? content
                      .find((item) => item.section === "youtube")
                      .content.replace(/<[^>]*>?/gm, "")
                      .trim()
                  : "https://youtube.com"
              }
              className="w-6"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  fill="currentColor"
                  d="M13.2 10L8 7v6l5.2-3zm6.4-4.8c.1.5.2 1.1.3 1.9.1.8.1 1.5.1 2.1v.8c0 2.2-.2 3.8-.4 4.8-.3.9-.8 1.5-1.7 1.7-.5.1-1.3.2-2.6.3-1.3.1-2.5.1-3.6.1L10 17c-4.2 0-6.8-.2-7.8-.4-.9-.3-1.5-.8-1.7-1.7-.1-.5-.2-1.1-.3-1.9-.1-.8-.1-1.5-.1-2.1L0 10c0-2.2.2-3.8.4-4.8.3-.9.8-1.5 1.7-1.7.5-.1 1.3-.2 2.7-.3 1.3-.1 2.5-.1 3.6-.1L10 3c4.2 0 6.8.2 7.8.4.9.3 1.5.9 1.8 1.8z"
                />
              </svg>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

const NavBar = ({ active, transparent = false }) => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  const { data, isLoaded } = useApiData(
    `${Settings.server}/api/v1/institutional?page_url=social-networks&lang=${language}`
  );

  const iconColor = transparent ? "text-white" : "text-blue";

  const logo = transparent
    ? language === "pt"
      ? whiteLogoPt
      : whiteLogoEn
    : language === "pt"
    ? blueLogoPt
    : blueLogoEn;

  const headerDesktopClass = transparent
    ? "mx-auto bg-transparent pt-11 w-reg"
    : "sticky top-0 z-30 w-full py-5 bg-white shadow-header negative-top";

  const headerMobileClass = transparent
    ? ""
    : "sticky top-0 z-30 bg-white w-full shadow-header negative-top";

  return (
    <>
      <Breakpoint
        customQuery="(max-width: 1024px)"
        className={headerMobileClass}
      >
        <NavBarMobile
          active={active}
          content={data}
          contentLoaded={isLoaded}
          transparent={transparent}
          logo={logo}
          language={language}
        />
      </Breakpoint>
      <Breakpoint
        customQuery="(min-width: 1025px)"
        className={headerDesktopClass}
      >
        <NavBarDesktop
          active={active}
          content={data}
          contentLoaded={isLoaded}
          transparent={transparent}
          iconColorClass={iconColor}
          logo={logo}
          language={language}
        />
      </Breakpoint>
    </>
  );
};

export default NavBar;
