import React from "react";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

const SocialShare = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className="mt-8 text-sm text-center uppercase lg:mt-0 lg:text-left font-reg-bold">
        {t("labels.share")}
      </p>
      <div className="flex flex-row items-center pt-4 justify-evenly lg:flex-col">
        <FacebookShareButton url={window.location.href} className="mb-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            className="w-8 h-8 lg:w-6 lg:h-6"
          >
            <g fill="currentColor">
              <path d="M30 2v28H2V2h28m1-1H1v30h30V1z" />
              <path d="M30 2v28H2V2h28m1-1H1v30h30V1z" />
              <path d="M13.892 24.123h3.357v-8.124h2.239l.297-2.799h-2.536l.003-1.402c0-.73.07-1.121 1.117-1.121h1.4v-2.8h-2.24c-2.69 0-3.637 1.358-3.637 3.642V13.2h-1.677V16h1.677v8.123z" />
            </g>
          </svg>
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href} className="mb-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            className="w-8 h-8 lg:w-6 lg:h-6"
          >
            <g fill="currentColor">
              <path d="M30 2v28H2V2h28m1-1H1v30h30V1z" />
              <path d="M30 2v28H2V2h28m1-1H1v30h30V1z" />
              <path d="M18.141 9.16c-1.529.556-2.495 1.991-2.385 3.562l.037.606-.612-.074c-2.226-.284-4.171-1.249-5.823-2.869L8.55 9.58l-.208.594c-.44 1.323-.159 2.721.758 3.661.489.52.379.594-.465.284-.294-.099-.55-.173-.575-.136-.085.087.208 1.212.44 1.657.318.619.966 1.224 1.676 1.583l.6.284-.709.012c-.685 0-.709.012-.636.273.245.804 1.211 1.657 2.287 2.029l.758.259-.66.396a6.855 6.855 0 01-3.278.915c-.551.012-1.004.062-1.004.099 0 .123 1.493.816 2.361 1.088 2.605.804 5.701.457 8.025-.915 1.651-.977 3.302-2.919 4.074-4.799.416-1.001.832-2.832.832-3.709 0-.569.037-.643.722-1.323.404-.396.783-.828.856-.952.123-.235.11-.235-.514-.025-1.04.371-1.187.322-.673-.235.379-.396.832-1.113.832-1.323 0-.037-.183.025-.392.136-.22.124-.709.309-1.076.42l-.66.211-.6-.409c-.33-.222-.795-.47-1.04-.544-.623-.173-1.578-.149-2.14.049z" />
            </g>
          </svg>
        </TwitterShareButton>
        <LinkedinShareButton url={window.location.href} className="mb-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            className="w-8 h-8 lg:w-6 lg:h-6"
          >
            <g fill="currentColor">
              <path d="M30 2v28H2V2h28m1-1H1v30h30V1z" />
              <path d="M30 2v28H2V2h28m1-1H1v30h30V1z" />
              <path d="M23.872 22.524v-5.822c0-3.119-1.665-4.571-3.885-4.571-1.792 0-2.594.986-3.042 1.677V12.37H13.57c.045.953 0 10.154 0 10.154h3.375v-5.671c0-.303.022-.606.111-.823.244-.606.799-1.234 1.731-1.234 1.222 0 1.71.931 1.71 2.296v5.433l3.375-.001zm-13.857-11.54c1.177 0 1.91-.78 1.91-1.755-.022-.996-.733-1.754-1.887-1.754s-1.91.758-1.91 1.754c0 .975.733 1.755 1.866 1.755h.021zm1.687 11.54V12.37H8.327v10.154h3.375z" />
            </g>
          </svg>
        </LinkedinShareButton>
      </div>
    </>
  );
};

export default SocialShare;
