import React from "react";
import { Switch, Route } from "react-router-dom";

import Gastronomia from "../pages/Gastronomia";
import NotFound from "../pages/404";

import RouteReceitas from "../routes/receitas";
import RouteRestaurantes from "../routes/restaurantes";
import RouteProdutos from "../routes/produtos";
import RouteExperiencias from "../routes/experiencias";
import RouteProdutores from "../routes/produtores";

const RouteGastronomia = () => {
  return (
    <Switch>
      <Route path="/pt/gastronomia" exact component={Gastronomia} />
      <Route path="/en/gastronomy" exact component={Gastronomia} />

      <Route path="/pt/gastronomia/produtos" component={RouteProdutos} />
      <Route path="/en/gastronomy/products" component={RouteProdutos} />

      <Route
        path="/pt/gastronomia/restaurantes"
        component={RouteRestaurantes}
      />
      <Route path="/en/gastronomy/restaurants" component={RouteRestaurantes} />

      <Route path="/pt/gastronomia/receitas" component={RouteReceitas} />
      <Route path="/en/gastronomy/recipes" component={RouteReceitas} />

      <Route
        path="/pt/gastronomia/experiências"
        component={RouteExperiencias}
      />
      <Route path="/en/gastronomy/experiences" component={RouteExperiencias} />

      <Route path="/pt/gastronomia/produtores" component={RouteProdutores} />
      <Route path="/en/gastronomy/producers" component={RouteProdutores} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default RouteGastronomia;
