import React from "react";

const Checkbox = ({ value, label, checked, onChange, type = false }) => (
  <label className="flex items-start justify-start">
    <div className="flex items-center justify-center flex-shrink-0 w-4 h-4 mr-2 bg-white border rounded-none border-gray-checkbox">
      <input
        type="checkbox"
        className="absolute opacity-0"
        checked={checked}
        onChange={() =>
          type !== false
            ? onChange(type, value, checked)
            : onChange(value, checked)
        }
      />
      <svg
        className="hidden w-3 h-3 pointer-events-none fill-current text-blue"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
      </svg>
    </div>
    <div className="text-base leading-none cursor-pointer select-none font-reg-light text-gray-dark">
      {label}
    </div>
  </label>
);

export default Checkbox;
