import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

const useTracking = (trackingId) => {
  const { listen } = useHistory();

  ReactGA.initialize(trackingId);

  useEffect(() => {
    const cleanup = listen((location) => {
      if (process.env.NODE_ENV === "production") {
        ReactGA.pageview(location.pathname);
      }
    });

    return cleanup;
  }, [listen]);
};

export default useTracking;
