import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Plyr from "plyr";
import { Breakpoint } from "react-socks";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import LanguageSwitcher from "../components/LanguageSwitcher";
import Related from "../components/Related";

import video from "../assets/video/home-compress.mp4";
import videoFrame from "../assets/images/frame.jpg";
import homeDisplay1 from "../assets/images/project-hero.jpg";
import homeDisplay2 from "../assets/images/home-display-1.jpg";
import homeDisplay3 from "../assets/images/home-display-2.jpg";
import homeDisplay4 from "../assets/images/home-display-3.jpg";
import homeHappening from "../assets/images/home-happening.jpg";
import homeGastronomy from "../assets/images/home-gastronomy.jpg";
import homeRestaurants from "../assets/images/gastronomy-restaurants.jpg";

const Video = () => {
  const { t } = useTranslation();

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className="relative w-full h-full h-screen overflow-hidden">
      <img
        className="absolute top-0 left-0 object-cover w-full h-full"
        src={videoFrame}
        alt={t("alt.homepageVideoFrame")}
        style={{ opacity: isVideoLoaded ? 0 : 1 }}
      />

      <video
        autoPlay
        loop
        playsInline
        muted
        src={video}
        onLoadedData={onLoadedData}
        className="absolute top-0 left-0 object-cover w-full h-full"
        style={{
          opacity: isVideoLoaded ? 1 : 0,
        }}
      />
    </div>
  );
};

const VideoPlayer = ({ videoURL, setStartVideo }) => {
  const playerRef = useRef();

  useEffect(() => {
    const player = new Plyr(playerRef.current, {
      controls: [
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "fullscreen",
      ],
      fullscreen: {
        enabled: true,
      },
    });

    player.source = {
      type: "video",
      sources: [
        {
          src: videoURL,
          provider: "youtube",
        },
      ],
    };

    player.fullscreen.enter();

    player.on("exitfullscreen", () => {
      player.stop();
      setStartVideo(false);
    });

    return () => {
      player.destroy();
    };
  }, [videoURL, setStartVideo]);

  return (
    <div className="absolute top-0 left-0">
      <video ref={playerRef} className="plyr-player" playsInline autoPlay />
    </div>
  );
};

const Home = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [startVideo, setStartVideo] = useState(false);

  const { data: content, isLoaded: contentLoaded } = useApiData(
    `${Settings.server}/api/v1/institutional?page_url=homepage&lang=${language}`
  );

  const { data: articles, isLoaded: articlesLoaded } = useApiData(
    `${Settings.server}/api/v1/article?per-page=3&sort=-publish_date&lang=${language}`
  );

  const { data: events, isLoaded: eventsLoaded } = useApiData(
    `${Settings.server}/api/v1/event?per-page=4&sort=-date_start&lang=${language}`
  );

  const scrollRef = useRef(null);
  const [stickyHeader, setStickyHeader] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (scrollRef.current) {
        const top = scrollRef.current.getBoundingClientRect().top;
        const height = scrollRef.current.getBoundingClientRect().height;
        if (height + top <= 0) {
          setStickyHeader(true);
        } else {
          setStickyHeader(false);
        }
      }
    });
  }, []);

  return (
    <>
      {startVideo && (
        <VideoPlayer
          videoURL="https://www.youtube.com/watch?v=GORuVk-ySEQ"
          setStartVideo={setStartVideo}
        />
      )}

      <Breakpoint customQuery="(max-width: 1169px)">
        <div className="relative w-full h-full h-screen overflow-hidden bg-black">
          <img
            className="absolute top-0 left-0 object-cover w-full h-full opacity-60 bg-overlay-5"
            src={videoFrame}
            alt={t("alt.homepageVideoFrame")}
          />
        </div>
      </Breakpoint>
      <Breakpoint customQuery="(min-width: 1170px)">
        <Video />
        <div className="video-overlay" />
      </Breakpoint>

      <div
        className="absolute top-0 left-0 z-10 w-full h-screen"
        ref={scrollRef}
      >
        <div className="flex flex-col items-center justify-center w-full h-full max-w-screen-md mx-auto lg:justify-around lg:flex-row">
          <h1 className="max-w-3xl mb-16 text-4xl sm:text-4.5xl leading-snug text-center text-white uppercase md:text-5xl sm:max-w-2xl lg:max-w-none lg:mb-0 lg:text-left lg:text-6xl font-reg-bold lg:leading-20">
            {t("homepage.title")}
          </h1>
          <button
            className="flex flex-row items-center justify-center px-4 py-2 bg-white lg:px-6 lg:py-3 rounded-xl text-blue hover:bg-transparent hover:text-white hover:border"
            onClick={() => setStartVideo(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              className="w-8 h-8"
            >
              <defs />
              <g fill="currentColor" fillRule="evenodd">
                <path d="M16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16S0 24.836556 0 16 7.163444 0 16 0zm0 1.33333333C7.89982367 1.33333333 1.33333333 7.89982367 1.33333333 16c0 8.1001763 6.56649034 14.6666667 14.66666667 14.6666667 8.1001763 0 14.6666667-6.5664904 14.6666667-14.6666667 0-8.10017633-6.5664904-14.66666667-14.6666667-14.66666667z" />
                <path d="M18.4811114 16.00000033L14.44444478 13.666667v4.66666667l4.03666662-2.33333334zm4.9544445-3.75666666c.1011111.36555555.1711111.85555555.2177778 1.47777777.0544444.62222223.0777777 1.15888889.0777777 1.62555556l.0466667.65333333c0 1.70333334-.1244444 2.95555556-.3422222 3.75666667-.1944445.7-.6455556 1.1511111-1.3455556 1.3455556-.3655555.1011111-1.0344444.1711111-2.0611111.2177777-1.0111111.0544445-1.9366666.0777778-2.7922222.0777778l-1.23666667.0466667c-3.25888889 0-5.28888889-.1244445-6.09-.3422222-.7-.1944445-1.15111111-.6455556-1.34555555-1.3455556-.10111111-.36555556-.17111111-.85555556-.21777778-1.47777778-.05444444-.62222222-.07777778-1.15888889-.07777778-1.62555555l-.04666666-.65333334c0-1.70333333.12444444-2.95555555.34222222-3.75666666.19444444-.7.64555555-1.15111111 1.34555555-1.34555556.36555556-.10111111 1.03444445-.17111111 2.06111111-.21777778 1.01111112-.05444444 1.93666667-.07777777 2.79222223-.07777777l1.23666666-.04666667c3.25888887 0 5.28888887.12444444 6.08999997.34222222.7.19444445 1.1511111.64555556 1.3455556 1.34555556z" />
              </g>
            </svg>
            <span className="pl-2 text-xs leading-3 font-reg">
              {t("homepage.completeVideo")}
            </span>
          </button>
        </div>
        <div className="absolute bottom-0 w-full">
          <div className="h-12 border-l border-white ml-1/2">
            <div className="flex items-center w-full h-full">
              <div className="absolute right-0 flex items-center text-sm text-white font-reg-light">
                {/*
                <Link
                  to={`/${language}/${t("slugs.terms")}`}
                  className="mr-6 opacity-50 hover:opacity-100 hover:underline"
                >
                  {t("labels.terms")}
                </Link>
                 */}
                <Link
                  to={`/${language}/${t("slugs.privacy")}`}
                  className="mr-12 opacity-50 hover:opacity-100 hover:underline"
                >
                  {t("labels.privacy")}
                </Link>
                <div className="flex items-center justify-end mr-12">
                  <LanguageSwitcher />
                  <div className="w-10 ml-1 bg-white h-xs" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {stickyHeader ? (
        <NavBar active="home" />
      ) : (
        <div className="absolute top-0 left-0 z-10 w-full">
          <NavBar transparent active="home" />
        </div>
      )}

      <div className="w-10/12 mx-auto lg:w-full lg:max-w-reg">
        <div className="flex flex-col items-center mt-10 text-center lg:mt-36 sm:mt-16 text-blue">
          <h2 className="text-2xl text-center font-reg-bold header-prefix md:text-4xl sm:text-3xl">
            {t("homepage.subtitles.million")}
          </h2>
          <div
            className="mt-6 mb-12 text-sm md:mb-24 font-reg-light institutional-content md:text-base"
            dangerouslySetInnerHTML={{
              __html: contentLoaded
                ? content.find(
                    (item) => item.section === "million-food-stories"
                  )?.content
                : "",
            }}
          />
        </div>
      </div>

      <div className="w-10/12 mx-auto lg:w-full lg:max-w-reg">
        <div className="grid grid-cols-1 gap-6 mb-6 md:gap-2 md:grid-cols-4 md:mb-36">
          <img
            src={homeDisplay1}
            alt={t("alt.homepageDisplay")}
            className="object-cover w-full h-52 md:h-heading"
          />
          <img
            src={homeDisplay2}
            alt={t("alt.homepageDisplay")}
            className="object-cover w-full h-52 md:h-heading"
          />
          <img
            src={homeDisplay3}
            alt={t("alt.homepageDisplay")}
            className="invisible hidden object-cover w-full h-heading md:block md:visible"
          />
          <img
            src={homeDisplay4}
            alt={t("alt.homepageDisplay")}
            className="invisible hidden object-cover w-full h-heading md:block md:visible"
          />
        </div>
      </div>

      <div className="bg-gray">
        <div className="w-10/12 pt-8 pb-8 mx-auto md:max-w-screen-md sm:pb-12 md:pb-26 sm:pt-12 md:pt-26">
          <h2 className="pb-8 text-2xl text-left sm:text-3xl md:text-4xl md:w-1/2 font-reg-bold text-blue header-prefix">
            {t("homepage.subtitles.region")}
          </h2>
          <article
            className="text-sm font-reg-light text-blue two-columns sm:text-base"
            dangerouslySetInnerHTML={{
              __html: contentLoaded
                ? content.find((item) => item.section === "region")?.content
                : "",
            }}
          />
        </div>
      </div>

      <div className="bg-white h-44 sm:h-56 md:h-72" />
      <div className="mb-10 bg-blue md:h-products md:mb-26">
        <div className="w-10/12 mx-auto lg:w-reg-grid">
          <div className="flex flex-col mb-8 md:mb-16 md:flex-row">
            <div
              className="relative w-full mx-auto -mt-32 bg-center bg-cover sm:w-10/12 md:w-1/2 md:mr-18 h-104 sm:h-125 md:h-card-home display-card"
              style={{
                backgroundImage: `url(${homeGastronomy})`,
              }}
            >
              <Link to={`/${language}/${t("slugs.gastronomy")}`}>
                <div className="absolute top-0 left-0 flex w-full h-full transition-none duration-200 ease-in sm:transition-all bg-overlay-3 hover:bg-overlay-5">
                  <div className="relative w-full text-white">
                    <div className="relative bottom-0 left-0 h-full p-6 md:h-auto md:p-0 md:absolute">
                      <h4 className="pb-4 text-2xl transition-none duration-200 ease-in-out sm:transition-all md:-ml-10 sm:text-3xl md:text-4xl font-reg-bold header-prefix">
                        {t("labels.gastronomy")}
                      </h4>
                      <p
                        className="w-full h-24 text-sm leading-5 transition-none duration-200 ease-in-out sm:transition-all md:text-base md:leading-7 md:-ml-10 font-reg-light md:w-112"
                        dangerouslySetInnerHTML={{
                          __html: contentLoaded
                            ? content.find(
                                (item) => item.section === "gastronomy-subtitle"
                              )?.content
                            : "",
                        }}
                      />
                      <div className="absolute bottom-0 pt-10 pb-6 pl-0 md:relative md:pb-10 md:pl-8">
                        <div className="flex flex-row items-center h-6 mt-5 text-base leading-3 uppercase font-reg">
                          <span className="uppercase bg-bottom bg-no-repeat underline-reg underline-reg-white">
                            {t("labels.explore")}
                          </span>
                          <div className="w-4 h-1 ml-2 min-w-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 3"
                              className="fill-current"
                            >
                              <defs />
                              <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div
              className="relative w-full mx-auto mt-8 bg-center bg-cover md:-mt-32 sm:w-10/12 md:w-1/2 md:ml-4 h-104 sm:h-125 md:h-card-home display-card"
              style={{
                backgroundImage: `url(${homeHappening})`,
              }}
            >
              <Link to={`/${language}/${t("slugs.happening")}`}>
                <div className="absolute top-0 left-0 flex w-full h-full transition-none duration-200 ease-in sm:transition-all bg-overlay-3 hover:bg-overlay-5">
                  <div className="relative w-full text-white">
                    <div className="relative bottom-0 left-0 h-full p-6 md:h-auto md:p-0 md:absolute">
                      <h4 className="pb-4 text-2xl transition-none duration-200 ease-in-out sm:transition-all md:-ml-10 sm:text-3xl md:text-4xl font-reg-bold header-prefix">
                        {t("labels.happening")}
                      </h4>
                      <p
                        className="w-full h-24 text-sm leading-5 transition-none duration-200 ease-in-out sm:transition-all md:text-base md:leading-7 md:-ml-10 font-reg-light md:w-112"
                        dangerouslySetInnerHTML={{
                          __html: contentLoaded
                            ? content.find(
                                (item) => item.section === "happening-subtitle"
                              )?.content
                            : "",
                        }}
                      />
                      <div className="absolute bottom-0 pt-10 pb-6 pl-0 md:relative md:pb-10 md:pl-8">
                        <div className="flex flex-row items-center h-6 mt-5 text-base leading-3 uppercase font-reg">
                          <span className="uppercase bg-bottom bg-no-repeat underline-reg underline-reg-white">
                            {t("labels.explore")}
                          </span>
                          <div className="w-4 h-1 ml-2 min-w-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 3"
                              className="fill-current"
                            >
                              <defs />
                              <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div
            className="relative w-full mx-auto -mb-24 bg-center bg-cover sm:w-10/12 md:w-full md:-mb-16 display-card h-104 sm:h-125 md:h-96"
            style={{
              backgroundImage: `url(${homeRestaurants})`,
            }}
          >
            <Link to={`/${language}/${t("slugs.restaurants")}`}>
              <div className="absolute top-0 left-0 flex w-full h-full transition-none duration-200 ease-in sm:transition-all bg-overlay-3 hover:bg-overlay-5">
                <div className="relative w-full text-white">
                  <div className="relative top-0 left-0 h-full p-6 md:h-auto md:p-0 md:absolute">
                    <h4 className="pb-4 text-2xl transition-none duration-200 ease-in-out md:pt-20 sm:transition-all md:-ml-10 sm:text-3xl md:text-4xl font-reg-bold header-prefix">
                      {t("homepage.subtitles.restaurants")}
                    </h4>
                    <p
                      className="w-full h-24 text-sm leading-5 transition-none duration-200 ease-in-out sm:transition-all md:text-base md:leading-7 md:-ml-10 font-reg-light md:w-112"
                      dangerouslySetInnerHTML={{
                        __html: contentLoaded
                          ? content.find(
                              (item) => item.section === "restaurants-subtitle"
                            )?.content
                          : "",
                      }}
                    />
                  </div>
                  <div className="absolute bottom-0 mb-6 ml-6 mr-8 md:mb-8 md:ml-0 md:right-0">
                    <div className="flex flex-row items-center h-6 mt-5 text-base leading-3 uppercase font-reg">
                      <span className="uppercase bg-bottom bg-no-repeat underline-reg underline-reg-white">
                        {t("labels.explore")}
                      </span>
                      <div className="w-4 h-1 ml-2 min-w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 3"
                          className="fill-current"
                        >
                          <defs />
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="h-16 bg-white" />

      <div className="pt-10 mx-auto bg-white max-w-reg md:pt-23 md:border-b md:border-gray md:pb-8">
        <div className="flex items-center justify-start w-10/12 pb-8 mx-auto lg:w-full md:pb-10 lg:justify-between">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-reg-bold text-blue header-prefix">
            {t("labels.news")}
          </h2>
          <Link
            to={`/${language}/${t("slugs.news")}`}
            className="invisible hidden text-sm leading-6 tracking-wide underline uppercase text-blue font-reg hover:no-underline lg:visible lg:block"
          >
            {t("homepage.moreNews")}
          </Link>
        </div>

        {articlesLoaded && articles.length > 0 && (
          <Related type="article" items={articles} columns={3} hideTitle />
        )}

        <div className="flex visible pt-4 pb-8 bg-white md:py-10 lg:invisible lg:hidden">
          <Link
            to={`/${language}/${t("slugs.events")}`}
            className="w-full text-sm leading-6 tracking-wide text-center underline uppercase text-blue font-reg hover:no-underline"
          >
            {t("homepage.moreNews")}
          </Link>
        </div>
      </div>

      <section>
        <div className="pt-8 mx-auto bg-white md:pt-18 max-w-reg">
          <div className="flex items-center justify-start w-10/12 mx-auto lg:w-full md:pb-10 lg:justify-between">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-reg-bold text-blue header-prefix">
              {t("labels.events")}
            </h2>
            <Link
              to={`/${language}/${t("slugs.events")}`}
              className="invisible hidden text-sm leading-6 tracking-wide underline uppercase text-blue font-reg hover:no-underline lg:visible lg:block"
            >
              {t("homepage.moreEvents")}
            </Link>
          </div>
        </div>

        {eventsLoaded && events.length > 0 && (
          <div className="py-4 bg-white lg:bg-gray lg:py-20">
            <div className="mx-auto md:max-w-reg">
              <Related
                type="event"
                items={events}
                columns={4}
                hideTitle
                border={false}
              />
            </div>
          </div>
        )}

        <div className="flex visible pt-4 pb-8 bg-white md:py-10 lg:invisible lg:hidden">
          <Link
            to={`/${language}/${t("slugs.events")}`}
            className="w-full text-sm leading-6 tracking-wide text-center underline uppercase text-blue font-reg hover:no-underline"
          >
            {t("homepage.moreEvents")}
          </Link>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
