import React from "react";
import { Switch, Route } from "react-router-dom";

import Produtores from "../pages/Produtores";
import ProdutorDetalhe from "../pages/ProdutorDetalhe";
import NotFound from "../pages/404";

const RouteProdutores = () => {
  return (
    <Switch>
      <Route path="/pt/gastronomia/produtores" exact component={Produtores} />
      <Route path="/pt/gastronomia/produtores/404" exact component={NotFound} />
      <Route path="/pt/gastronomia/produtores/:slug" component={ProdutorDetalhe} />

      <Route path="/en/gastronomy/producers" exact component={Produtores} />
      <Route path="/en/gastronomy/producers/404" exact component={NotFound} />
      <Route path="/en/gastronomy/producers/:slug" component={ProdutorDetalhe} />
    </Switch>
  );
};

export default RouteProdutores;
