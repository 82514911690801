import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";
import useModal from "../hooks/useModal";

import Loading from "../components/Loading";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import SocialShare from "../components/SocialShare";
import Related from "../components/Related";

const Preparation = ({ list }) => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    setContent(list.map((item) => item.replace("\r", "")).filter(Boolean));
  }, [list]);

  return (
    <ol className="text-base leading-7 list-inside col-gap-7 institutional-content font-reg-light text-blue preparation-list">
      {content.map((item, i) => (
        <li
          key={i}
          dangerouslySetInnerHTML={{
            __html: item,
          }}
        />
      ))}
    </ol>
  );
};

const Gallery = ({ images, index, setIndex, setOpen }) => {
  return (
    <Lightbox
      mainSrc={images[index]}
      nextSrc={images[(index + 1) % images.length]}
      prevSrc={images[(index + images.length - 1) % images.length]}
      onCloseRequest={() => setOpen(false)}
      onMovePrevRequest={() =>
        setIndex((index + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setIndex((index + 1) % images.length)}
    />
  );
};

const ReceitaDetalhe = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { slug } = useParams();
  let history = useHistory();

  const { data: recipe, isLoaded: loaded, isError } = useApiData(
    `${Settings.server}/api/v1/recipe/${slug}?expand=media&lang=${language}`
  );

  if (isError) {
    history.push(`/${language}/${t("slugs.recipes")}/404`);
  }

  const { openModal, closeModal, isOpen, Modal } = useModal();

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    let imagesList = [];
    if (recipe.media?.hasOwnProperty("9")) {
      imagesList = recipe.media["9"].map((image) => image.path);
    }
    if (recipe.media?.hasOwnProperty("2")) {
      recipe.media["2"].forEach((image) => {
        imagesList.push(image.path);
      });
    }
    setImages(imagesList);
  }, [recipe]);

  const [related, setRelated] = useState([]);
  const {
    data: relatedRecipes,
    isLoaded: relatedRecipesLoaded,
    isError: relatedRecipesError,
  } = useApiData(
    `${Settings.server}/api/v1/recipe?per-page=5&sort=-id&lang=${language}`
  );

  useEffect(() => {
    if (relatedRecipesLoaded && !relatedRecipesError) {
      let productsList = relatedRecipes.filter((e) => e.slug !== slug);
      if (productsList.length > 4) {
        productsList.pop();
      }
      setRelated(productsList);
    }
  }, [relatedRecipes, slug, relatedRecipesLoaded, relatedRecipesError]);

  return (
    <>
      <NavBar active="gastronomia" />

      {loaded ? (
        <main className="bg-gray">
          <div
            className="relative flex items-center justify-center bg-local bg-center bg-cover h-detail-heading"
            style={{
              backgroundImage: recipe.media?.hasOwnProperty("9")
                ? `url(${recipe.media["9"][0]?.path})`
                : "none",
            }}
          >
            <div className="absolute top-0 left-0 w-full h-full bg-overlay-3">
              <div className="flex items-center justify-center h-full mx-auto lg:justify-start lg:w-reg-grid">
                <div className="w-10/12 text-white lg:w-9/12">
                  <h1 className="text-3xl leading-8 text-center uppercase lg:text-left lg:text-5xl lg:leading-14 font-reg-bold">
                    {recipe.name}
                  </h1>

                  <div className="flex invisible hidde pt-7 lg:block lg:visible">
                    <div className="pr-10">
                      <p className="font-reg-bold">
                        {t("labels.recipeCategory")}:
                      </p>
                      <p className="font-reg-light">{recipe.category?.name}</p>
                    </div>
                    <div>
                      <p className="font-reg-bold">{t("labels.author")}:</p>
                      <p className="font-reg-light">{recipe.source}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between visible block w-10/12 mx-auto sm:justify-start pt-7 lg:hidden lg:invisible text-blue">
            <div className="pr-10">
              <p className="font-reg-bold">{t("labels.recipeCategory")}:</p>
              <p className="font-reg-light">{recipe.category?.name}</p>
            </div>
            <div>
              <p className="font-reg-bold">{t("labels.author")}:</p>
              <p className="font-reg-light">{recipe.source}</p>
            </div>
          </div>

          <section className="w-10/12 mx-auto lg:w-reg-grid lg:pt-23">
            <div className="flex flex-col flex-col-reverse lg:flex-row">
              <div className="ml-0 lg:-ml-25 text-blue">
                <SocialShare />
              </div>

              <div className="w-1/12 mr-7" />

              <div className="flex flex-col mt-8 lg:w-11/12 lg:mt-0">
                <div className="flex flex-col justify-between md:flex-row">
                  <div className="flex flex-col md:w-6/12">
                    <h2 className="text-2xl md:pb-5 md:text-3xl text-blue font-reg-bold header-prefix">
                      {t("labels.ingredients")}
                    </h2>

                    <div className="text-base text-blue institutional-content font-reg-light">
                      <div className="institutional-content">
                        {recipe.ingredients.length > 0 && (
                          <ul>
                            {recipe.ingredients.map((ingredient) => (
                              <li key={ingredient.id}>
                                <strong>{ingredient.quantity}</strong>{" "}
                                {ingredient.description}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 md:mt-0 md:w-5/12">
                    <div
                      className="relative w-full cursor-pointer gallery"
                      onClick={() => setIsGalleryOpen(true)}
                    >
                      <img
                        src={recipe.media["9"][0]?.path}
                        alt={recipe.name}
                        className="object-cover w-full max-h-110 h-110"
                      />
                      <div className="absolute bottom-0 w-full transition-opacity duration-300 ease-in-out bg-black h-18 opacity-35" />
                      <p className="absolute bottom-0 w-full mb-6 text-xl text-center text-white font-reg-bold">
                        {t("labels.viewGallery")}
                      </p>
                    </div>
                    {images.length > 0 && isGalleryOpen && (
                      <Gallery
                        images={images}
                        setOpen={setIsGalleryOpen}
                        setIndex={setPhotoIndex}
                        index={photoIndex}
                      />
                    )}

                    <div className="px-6 pt-8 pb-6 mt-4 bg-white shadow-hero text-blue">
                      <h2 className="text-2xl leading-6 header-prefix font-reg-bold">
                        {t("labels.know")}
                      </h2>
                      <div
                        className="pt-2 text-sm leading-6 institutional-content font-reg-light"
                        dangerouslySetInnerHTML={{
                          __html:
                            recipe.observations?.trim().substring(0, 150) +
                            " ...",
                        }}
                      />
                      <div className="flex flex-row items-center justify-end w-full">
                        <button
                          onClick={(e) => openModal(e)}
                          className="flex items-center h-6 mt-5 text-base leading-3 text-right uppercase font-reg text-blue focus:outline-none"
                        >
                          <span className="bg-bottom bg-no-repeat underline-reg underline-reg-blue">
                            {t("labels.knowMore")}
                          </span>
                          <div className="w-4 h-1 ml-2 text-blue min-w-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 3"
                              className="fill-current"
                            >
                              <defs />
                              <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M21.0499 0l1.1252 1.0578H0v.8848h22.1751L21.0499 3h1.3543L24 1.5002 22.4042 0z"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>
                      {isOpen && (
                        <Modal>
                          <div className="fixed inset-x-0 top-0 bottom-0 z-30 flex items-center justify-center w-full h-full bg-overlay-6">
                            <div className="w-10/12 mx-auto bg-white h-10/12 md:h-auto md:w-auto">
                              <div className="flex items-center justify-end border-b border-cool-gray-300 h-1/12 px-7">
                                <span
                                  className="text-3xl cursor-pointer text-cool-gray-700"
                                  onClick={(e) => closeModal(e)}
                                >
                                  &times;
                                </span>
                              </div>
                              <div className="px-4 py-6 overflow-y-scroll h-11/12 md:px-10 md:py-12 text-blue">
                                <h3 className="pb-6 text-2xl leading-6 header-prefix font-reg-bold">
                                  {t("labels.know")}
                                </h3>
                                <div
                                  className="text-sm leading-6 institutional-content md:w-194 font-reg-light"
                                  dangerouslySetInnerHTML={{
                                    __html: recipe.observations,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col pt-10 md:pt-20">
                  <h2 className="pb-5 text-2xl md:pb-10 md:text-3xl text-blue font-reg-bold header-prefix">
                    {t("labels.preparation")}
                  </h2>

                  <div className="w-full pb-8">
                    <Preparation list={recipe.preparation} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {relatedRecipesLoaded && related.length > 0 && (
            <section className="pt-8 pb-8 bg-gray md:pt-16 md:pb-16 lg:pt-20 lg:pb-20">
              <div className="mx-auto md:max-w-reg-grid">
                <Related type="recipe" items={related} columns={4} />
              </div>
            </section>
          )}
        </main>
      ) : (
        <Loading />
      )}

      <Footer />
    </>
  );
};

export default ReceitaDetalhe;
