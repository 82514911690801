import React from "react";
import { useTranslation } from "react-i18next";

import Settings from "../helpers/settings";
import useApiData from "../hooks/useApiData";

import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

import igcatLogo from "../assets/logo/logo-igcat.svg";
import rcEnLogo from "../assets/logo/logo-rc-reg2021-en.svg";

import heroImage from "../assets/images/project-hero.jpg";
import aboutBackground from "../assets/images/project-about.png";
import regionLeft from "../assets/images/project-region-left.jpg";
import regionRight from "../assets/images/project-region-right.jpg";
import projectGoals from "../assets/images/project-goals.jpg";
import projectRegions from "../assets/images/project-regions.png";

import regionSlovenia from "../assets/images/regions/Slovenia.jpg";
import regionCoimbra from "../assets/images/regions/Coimbra.png";
import regionKuopio from "../assets/images/regions/Kuopio.jpg";
import regionSibiu from "../assets/images/regions/Sibiu.jpg";
import regionSouthAegean from "../assets/images/regions/South_Aegean.jpg";
import regionGalway from "../assets/images/regions/Galway.jpg";
import regionNorthBrabant from "../assets/images/regions/North_Brabant.jpg";
import regionAarhus from "../assets/images/regions/Aarhus.jpg";
import regionEastLombardy from "../assets/images/regions/East_Lombardy.jpg";
import regionRiga from "../assets/images/regions/Riga.jpg";
import regionCatalonia from "../assets/images/regions/Catalonia.jpg";
import regionMinho from "../assets/images/regions/Minho.jpg";

import ccdrcLogo from "../assets/logo/founders/ccdrc.svg";
import cimLogo from "../assets/logo/founders/cim.svg";
import politecnicoCoimbraLogo from "../assets/logo/founders/politecnico-coimbra.svg";
import ucLogo from "../assets/logo/founders/uc.svg";
import turismoCentroLogo from "../assets/logo/founders/turismo-centro.svg";
import turismoCoimbraLogo from "../assets/logo/founders/turismo-coimbra.svg";

import adeloLogo from "../assets/logo/stakeholders/adelo.svg";
import adiberLogo from "../assets/logo/stakeholders/Adiber.svg";
import adicesLogo from "../assets/logo/stakeholders/Adices.svg";
import adiramLogo from "../assets/logo/stakeholders/Adiram.svg";
import ahrespLogo from "../assets/logo/stakeholders/AHRESP.svg";
import apteceLogo from "../assets/logo/stakeholders/Aptece.svg";
import arsCentroLogo from "../assets/logo/stakeholders/ARSCentro.svg";
import cearteLogo from "../assets/logo/stakeholders/CEARTE.svg";
import maisFuturoLogo from "../assets/logo/stakeholders/CoimbramaisFuturo.svg";
import cvrBairradaLogo from "../assets/logo/stakeholders/CVR_Bairrada.svg";
import cvrDaoLogo from "../assets/logo/stakeholders/CVR_Dao.svg";
import dueceiraLogo from "../assets/logo/stakeholders/Dueceira.svg";
import fpcgLogo from "../assets/logo/stakeholders/FPCG.svg";
import inatureLogo from "../assets/logo/stakeholders/INATURE.svg";
import licorBeiraoLogo from "../assets/logo/stakeholders/LicorBeirao.svg";
import lousamelLogo from "../assets/logo/stakeholders/LOUSAMEL.svg";
import lugradeLogo from "../assets/logo/stakeholders/Lugrade.svg";
import pinhaisZezereLogo from "../assets/logo/stakeholders/PinhaisZezere.svg";
import praxisLogo from "../assets/logo/stakeholders/Praxis.svg";
import rcmmLogo from "../assets/logo/stakeholders/RCMM.svg";
import terrasSicoLogo from "../assets/logo/stakeholders/TerrasSico.svg";

const Project = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { data: content, isLoaded: loaded } = useApiData(
    `${Settings.server}/api/v1/institutional?page_url=project&lang=${language}`
  );

  return (
    <>
      <NavBar active="projeto" />

      <main>
        <div
          className="relative flex items-center justify-center bg-local bg-center bg-cover h-72 sm:h-80 md:h-heading"
          style={{
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-overlay-4">
            <h1 className="text-4xl text-center text-white sm:text-5xl md:text-6xl lg:text-7xl font-reg sm:font-reg-light">
              {t("project.title")}
            </h1>
          </div>
        </div>

        <div className="w-10/12 mx-auto bg-white lg:max-w-screen-md">
          <div className="pt-8 md:pt-16 lg:pt-26">
            <h2 className="text-2xl text-left sm:text-3xl md:text-4xl font-reg-bold text-blue header-prefix">
              {t("project.subtitles.region")}
            </h2>
          </div>
          <div className="flex flex-col pt-8 pb-16 text-sm leading-8 md:text-base md:pt-10 md:flex-row text-blue font-reg-light md:pb-26">
            <div
              className="w-full md:w-1/2 md:pr-4 institutional-content"
              dangerouslySetInnerHTML={{
                __html: loaded
                  ? content.find((item) => item.section === "region-left")
                      ?.content
                  : "",
              }}
            />
            <div
              className="w-full md:w-1/2 md:pl-4 institutional-content"
              dangerouslySetInnerHTML={{
                __html: loaded
                  ? content.find((item) => item.section === "region-right")
                      ?.content
                  : "",
              }}
            />
          </div>
        </div>

        <div className="flex flex-col w-10/12 pb-16 mx-auto md:flex-row lg:max-w-reg lg:pb-26">
          <img
            src={regionLeft}
            alt={t("alt.projectDisplay")}
            className="object-cover w-full md:w-1/2 md:pr-4 h-52 md:h-heading"
          />
          <img
            src={regionRight}
            alt={t("alt.projectDisplay")}
            className="object-cover w-full pt-6 md:w-1/2 md:pt-0 md:pl-4 h-52 md:h-heading"
          />
        </div>
      </main>

      <div className="bg-gray">
        <div
          className="invisible hidden bg-local bg-center bg-cover md:block md:visible h-heading"
          style={{
            backgroundImage: `url(${aboutBackground})`,
          }}
        />

        <div className="w-full mx-auto text-center bg-white md:max-w-4xl md:-mt-26 shadow-hero">
          <h2 className="w-10/12 pt-8 mx-auto text-2xl text-left md:text-center sm:text-3x md:text-4xl font-reg-bold text-blue header-prefix">
            {t("project.subtitles.project")}
          </h2>
          <div
            className="pt-4 pb-8 text-base text-left md:text-xl md:text-center institutional-content font-reg-light px-9"
            dangerouslySetInnerHTML={{
              __html: loaded
                ? content.find((item) => item.section === "project-hero")
                    ?.content
                : "",
            }}
          />
        </div>

        <div
          className="w-10/12 pt-8 pb-8 mx-auto text-sm leading-7 md:text-base md:max-w-5xl institutional-content md:pb-18 md:pt-15 text-blue font-reg-light"
          dangerouslySetInnerHTML={{
            __html: loaded
              ? content.find((item) => item.section === "project-description")
                  ?.content
              : "",
          }}
        />

        <div className="flex items-center justify-center pb-8 md:pb-18">
          <a
            href="/bidbook-pt.pdf"
            download="bidbook-pt.pdf"
            className="flex items-center justify-center p-4 text-blue font-reg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              className="w-8 h-8"
            >
              <defs />
              <g fill="currentColor" fillRule="evenodd">
                <path d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm0 1.333C7.9 1.333 1.333 7.9 1.333 16S7.9 30.667 16 30.667 30.667 24.1 30.667 16 24.1 1.333 16 1.333z" />
                <path d="M16.778 13.667h4.278l-4.278-4.278v4.278m-5.445-5.445h6.223l4.666 4.667v9.333c0 .86-.696 1.556-1.555 1.556h-9.334a1.556 1.556 0 01-1.555-1.556V9.778c0-.86.696-1.556 1.555-1.556m3.19 7.311c-.016.031-.226 1.37-1.634 3.648 0 0-2.722 1.416-2.077 2.473.521.84 1.805-.03 2.91-2.084 0 0 1.415-.498 3.297-.638 0 0 3.002 1.346 3.414-.085.405-1.447-2.38-1.12-2.877-.973 0 0-1.556-1.05-1.945-2.496 0 0 .887-3.072-.474-3.034-1.361.04-.848 2.435-.615 3.19m.63.808c.024.008.366.941 1.47 1.914 0 0-1.812.357-2.636.7 0 0 .777-1.346 1.166-2.614m3.057 2.116c.451-.125 1.812.116 1.758.373-.047.257-1.758-.373-1.758-.373m-5.499 1.43c-.412.965-1.12 1.556-1.299 1.556-.179 0 .545-1.244 1.299-1.555m2.442-5.39c0-.055-.28-1.711 0-1.672.42.062 0 1.617 0 1.672z" />
              </g>
            </svg>
            <span className="pl-2 uppercase">
              {t("labels.viewApplication")}
            </span>
          </a>
        </div>

        <div className="flex flex-col items-center justify-center w-10/12 pb-10 mx-auto md:flex-row md:pb-20">
          <a
            href="https://igcat.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={igcatLogo}
              alt={t("alt.logoIGCAT")}
              className="h-16 md:pr-7"
            />
          </a>
          <a
            href="https://www.europeanregionofgastronomy.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={rcEnLogo}
              alt={t("alt.logoCIM")}
              className="h-16 mt-8 md:mt-0 md:pl-7"
            />
          </a>
        </div>

        <div
          className="visible block bg-local bg-center bg-cover md:invisible md:hidden h-80"
          style={{
            backgroundImage: `url(${aboutBackground})`,
          }}
        />
      </div>

      <div className="flex flex-col items-center justify-center w-10/12 pt-10 pb-8 mx-auto bg-white md:max-w-screen-xl md:flex-row md:pb-20 md:pt-23">
        <div className="w-full md:w-7/12 pr-7">
          <h2 className="text-2xl text-left sm:text-3xl md:text-4xl font-reg-bold text-blue header-prefix">
            {t("project.subtitles.objectives")}
          </h2>
          <div
            className="institutional-content"
            dangerouslySetInnerHTML={{
              __html: loaded
                ? content.find((item) => item.section === "objectives")?.content
                : "",
            }}
          />
        </div>
        <div className="w-full md:w-5/12">
          <img src={projectGoals} alt={t("alt.projectObjectives")} />
        </div>
      </div>

      <div className="bg-gray">
        <div className="flex flex-col items-center w-10/12 mx-auto md:max-w-5xl">
          <h2 className="pt-8 text-2xl sm:text-3xl md:text-4xl font-reg-bold text-blue md:pt-23 header-prefix">
            {t("project.subtitles.otherRegions")}
          </h2>
          <div
            className="pt-5 pb-12 text-sm text-blue font-reg-light institutional-content md:text-base"
            dangerouslySetInnerHTML={{
              __html: loaded
                ? content.find((item) => item.section === "other-regions")
                    ?.content
                : "",
            }}
          />
        </div>

        <div className="w-10/12 pb-16 mx-auto md:max-w-screen-xl md:pb-24">
          <div className="flex">
            <div className="z-10 px-6 py-4 bg-white md:py-0 md:mt-8 md:max-w-2xl md:ml-3 md:-mb-8 md:-mr-48 shadow-hero-sm">
              <h4
                className="text-base font-bold leading-6 md:pt-10 font-reg text-blue"
                dangerouslySetInnerHTML={{
                  __html: loaded
                    ? content.find(
                        (item) => item.section === "other-regions-hero-title"
                      )?.content
                    : "",
                }}
              />
              <div
                className="institutional-content"
                dangerouslySetInnerHTML={{
                  __html: loaded
                    ? content.find(
                        (item) => item.section === "other-regions-hero-list"
                      )?.content
                    : "",
                }}
              />
            </div>
            <div className="invisible hidden md:visible md:block">
              <img
                src={projectRegions}
                alt={t("alt.projectOtherRegions")}
                className="h-96"
              />
            </div>
          </div>
        </div>

        <div className="grid w-10/12 grid-cols-2 gap-4 pb-10 mx-auto sm:grid-cols-3 md:grid-cols-4 md:max-w-screen-xl lg:grid-cols-6 md:pb-20 md:gap-7">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/slovenia-2021/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionSlovenia}
                alt={t("alt.logoSloveniaRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  Slovenia 2021
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/coimbra-region-2021/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionCoimbra}
                alt={t("alt.logoCoimbraRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  Coimbra Region 2021
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/kuopio-2020-2021/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionKuopio}
                alt={t("alt.logoKuopioRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  Kuopio 2020
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/sibiu-2019/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionSibiu}
                alt={t("alt.logoSibiuRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  Sibiu 2019
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/south-aegean-2019/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionSouthAegean}
                alt={t("alt.logoAegeanRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  South Aegean 2019
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/galway-west-of-ireland-2018/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionGalway}
                alt={t("alt.logoGalwayRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  Galway-West of Ireland 2018
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/north-brabant-2018/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionNorthBrabant}
                alt={t("alt.logoBrabantRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  North Brabant 2018
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/central-denmark-region-2017/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionAarhus}
                alt={t("alt.logoAarhusRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  Aarhus-Central Denmark 2017
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/east-lombardy-2017/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionEastLombardy}
                alt={t("alt.logoLombardyRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  East Lombardy 2017
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/riga-gauja-2017/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionRiga}
                alt={t("alt.logoRigaRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  Riga-Gauja 2017
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/catalonia-2016/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionCatalonia}
                alt={t("alt.logoCataloniaRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  Catalonia 2016
                </p>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.europeanregionofgastronomy.org/platform/minho-2016/"
            className="other-regions"
          >
            <div className="relative h-36">
              <img
                className="object-cover w-full h-full"
                src={regionMinho}
                alt={t("alt.logoMinhoRegion")}
              />
              <div className="absolute bottom-0 flex items-center justify-center w-full h-12 transition-all duration-200 ease-in bg-blue other-regions-text">
                <p className="mx-4 text-sm text-center text-white font-reg-bold">
                  Minho 2016
                </p>
              </div>
            </div>
          </a>
        </div>

        <div className="py-8 bg-white md:py-20">
          <div className="flex flex-col w-10/12 mx-auto md:max-w-screen-xl md:flex-row">
            <div className="w-full md:w-5/12">
              <h4 className="text-sm text-blue font-reg-bold">
                {t("labels.founders")}
              </h4>
              <div className="grid grid-cols-3 gap-3">
                <img
                  src={cimLogo}
                  alt="Logótipo da Comunidade Intermunicipal - Região de Coimbra"
                />
                <img
                  src={turismoCoimbraLogo}
                  alt="Logótipo do Turismo de Portugal - Escola de Coimbra"
                />
                <img src={ucLogo} alt="Logótipo da Universidade de Coimbra" />
                <img
                  src={ccdrcLogo}
                  alt="Logótipo da Comissão de Coordenação e Desenvolvimento Regional do Centro"
                />
                <img
                  src={turismoCentroLogo}
                  alt="Logótipo do Turismo do Centro de Portugal"
                />
                <img
                  src={politecnicoCoimbraLogo}
                  alt="Logótipo do Politécnico de Coimbra"
                />
              </div>
            </div>
            <div className="w-full md:w-7/12 md:ml-1/12">
              <h4 className="mb-4 text-sm text-blue font-reg-bold">
                {t("labels.stakeholders")}
              </h4>
              <div className="grid grid-cols-3 gap-3 md:gap-4 md:grid-cols-7">
                <img src={adeloLogo} alt={t("alt.logoAdelo")} />
                <img src={adiberLogo} alt={t("alt.logoAdiber")} />
                <img src={adicesLogo} alt={t("alt.logoAdices")} />
                <img src={adiramLogo} alt={t("alt.logoAdiram")} />
                <img src={ahrespLogo} alt={t("alt.logoAhresp")} />
                <img src={apteceLogo} alt={t("alt.logoApetece")} />
                <img src={arsCentroLogo} alt={t("alt.logoArsCentro")} />
                <img src={cearteLogo} alt={t("alt.logoCearte")} />
                <img src={maisFuturoLogo} alt={t("alt.logoMaisFuturo")} />
                <img src={cvrBairradaLogo} alt={t("alt.logoCvrBairrada")} />
                <img src={cvrDaoLogo} alt={t("alt.logoCvrDao")} />
                <img src={dueceiraLogo} alt={t("alt.logoDuaceiro")} />
                <img src={fpcgLogo} alt={t("alt.logoFpcg")} />
                <img src={inatureLogo} alt={t("alt.logoInature")} />
                <img src={licorBeiraoLogo} alt={t("alt.logoLicorBeirao")} />
                <img src={lousamelLogo} alt={t("alt.logoLousamel")} />
                <img src={lugradeLogo} alt={t("alt.logoLugrade")} />
                <img src={pinhaisZezereLogo} alt={t("alt.logoPinhaisZezere")} />
                <img src={praxisLogo} alt={t("alt.logoPraxis")} />
                <img src={rcmmLogo} alt={t("alt.logoRcmm")} />
                <img src={terrasSicoLogo} alt={t("alt.logoTerrasSico")} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Project;
