import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const PageButton = ({ page, active, onClick }) => {
  const buttonClass =
    "px-4 py-2 focus:outline-none " +
    (active ? "text-white rounded bg-blue cursor-default" : "text-gray-dark");

  return (
    <button
      type="button"
      onClick={() => (!active ? onClick(page) : null)}
      className={buttonClass}
    >
      {page}
    </button>
  );
};

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const { t } = useTranslation();

  const [pageButtonsList, setPageButtonsList] = useState([]);
  useEffect(() => {
    let list = [];
    for (let i = 1; i <= totalPages; i++) {
      list.push(
        <PageButton
          key={i}
          active={i === currentPage}
          page={i}
          onClick={handlePageChange}
        />
      );
    }
    setPageButtonsList(list);
  }, [currentPage, totalPages, handlePageChange]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [currentPage]);

  const prevButtonClass =
    "p-3 border rounded shadow-button focus:outline-none border-grayish-blue " +
    (currentPage === 1
      ? "bg-white cursor-default"
      : "text-white bg-blue hover:bg-transparent hover:text-blue hover:border-blue");
  const nextButtonClass =
    "p-3 border rounded shadow-button focus:outline-none border-grayish-blue " +
    (currentPage === totalPages
      ? "bg-white cursor-default"
      : "text-white bg-blue hover:bg-transparent hover:text-blue hover:border-blue");

  const prevSpanClass =
    "pl-1" + (currentPage === 1 ? " text-black opacity-25" : "");
  const prevSVGClass =
    "w-3 transform rotate-90" +
    (currentPage === 1 ? " text-black opacity-25" : "");

  const nextSpanClass =
    "pl-1" + (currentPage === totalPages ? " text-black opacity-25" : "");
  const nextSVGClass =
    "w-3 transform -rotate-90" +
    (currentPage === totalPages ? " text-black opacity-25" : "");

  return (
    <div className="flex flex-row items-center justify-between pt-4 text-sm font-reg-bold">
      <button
        onClick={() =>
          currentPage !== 1 ? handlePageChange(--currentPage) : null
        }
        type="button"
        className={prevButtonClass}
      >
        <div className="flex flex-row items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
            className={prevSVGClass}
          >
            <path fill="currentColor" d="M0 3l6 6 6-6H0z" />
          </svg>
          <span className={prevSpanClass}>{t("labels.prev")}</span>
        </div>
      </button>

      <div className="flex-row invisible hidden sm:flex sm:visible">
        {pageButtonsList}
      </div>

      <button
        onClick={() =>
          currentPage !== totalPages ? handlePageChange(++currentPage) : null
        }
        type="button"
        className={nextButtonClass}
      >
        <div className="flex flex-row items-center justify-between">
          <span className={nextSpanClass}>{t("labels.next")}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
            className={nextSVGClass}
          >
            <path fill="currentColor" d="M0 3l6 6 6-6H0z" />
          </svg>
        </div>
      </button>
    </div>
  );
};

export default Pagination;
